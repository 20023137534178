import React from 'react'

const FinishOrder = () => {
  return (
    <div>
      FinishOrder
    </div>
  )
}

export default FinishOrder
