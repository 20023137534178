const OfferCategoryParser = (categoryKey) => {
  switch (parseInt(categoryKey)) {
    case 1:
      return "Elektronika";
    case 2:
      return "Računalništvo";
    case 3:
      return "Gospodinjski aparati";
    case 4:
      return "Oblačila";
    case 5:
      return "Lepota in zdravje";
    case 6:
      return "Šport";
    case 7:
      return "Igrače";
    case 8:
      return "Dom";
    case 9:
      return "Vrt";
    case 10:
      return "Hrana in pijača";
    case 11:
      return "Avtomobilizem";
    case 12:
      return "Knjige";
    case 13:
      return "Glasba in filmi";
    case 14:
      return "Pisarna in šola";
    case 15:
      return "Bela tehnika";
    case 16:
      return "Orodja";
    case 17:
      return "Strojni deli in komponente";
    case 18:
      return "Gradbeni material";
    case 19:
      return "Indostrijski material (les, kovina, plastika...)";
    case 20:
      return "Leseni material in izdelki";
    case 21:
      return "Vodovodne storitve";
    case 22:
      return "Elektro storitve";
    case 23:
      return "Lesne storitve";
    case 24:
      return "Selitvene storitve";
    case 25:
      return "Čiščenje";
    case 26:
      return "Gradbene storitve";
    case 27:
      return "Vzdrževalne storitve";
    case 28:
      return "Avtomehanične storitve";
    case 29:
      return "Avtokleparske storitve";
    case 30:
      return "Avtoličarske storitve";
    case 31:
      return "Prevozniške storitve";
    case 32:
      return "Gradbeno svetovanje in načrtovanje";
    case 33:
      return "Poslovno svetovanje";
    case 34:
      return "Finančno svetovanje";
    case 35:
      return "Pravno svetovanje";
    case 36:
      return "Zavarovalniško svetovanje";
    case 37:
      return "IT svetovanje";
    case 38:
      return "Svetovanje za kibernetsko varnost";
    case 39:
      return "Spletno oblikovanje in razvoj";
    case 40:
      return "Upravljanje družbenih omrežij";
    case 41:
      return "Digitalno oglaševanje";
    case 42:
      return "Tiskarske storitve";
    case 43:
      return "Organizacija dogodkov";
    case 44:
      return "Fotografske storitve";
    case 45:
      return "Video produkcija";
    case 46:
      return "Prevajanje in prepisovanje";
    case 47:
      return "Računovodske storitve";
    case 48:
      return "Kadrovske storitve in zaposlovanje";
    case 49:
      return "Osebno usposabljanje (coaching)";
    case 50:
      return "Gostinske storitve (catering)";
    case 51:
      return "Frizerkse in kozmetične storitve";
    case 52:
      return "Masažne storitve";
    case 53:
      return "Fitnes storitve";
    case 54:
      return "Individualno zdravstveno svetovanje";
    case 55:
      return "Rehabilitacijske storitve";
    default:
      return `${categoryKey} NAPAKA`;
  }
};

export default OfferCategoryParser;
