import React from "react";

import "../Assets/Styles/Components/WarningWindow.css"

const WarningWindow = (props) => {
  return (
    <div className="warning-page">
      <div className="warning-box">
        <div className="warning-title">{props.title}</div>
        <div className="warning-text">{props.text}</div>
        <div className="button-box">
            <button className="button-1" type="button" onClick={props.goback}>{props.gobackbtn}</button>
            <button className="button-1" type="button" onClick={props.proceed}>{props.proceedbtn}</button>
        </div>
      </div>
    </div>
  );
};

export default WarningWindow;
