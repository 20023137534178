import axios from "axios";

const deleteOfferData = (offerArr) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_DELETE_OFFER_DATA, {
        params: { offerArr },
      }, {withCredentials: true})
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export default deleteOfferData;
