import axios from "axios";

const insertNewOffer = (offer, companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_INSERT_NEW_OFFER, { offer, companyId }, { withCredentials: true })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default insertNewOffer;
