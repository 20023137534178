const legalFromParser = (legalFromKey) => {
  switch (legalFromKey) {
    case 1:
      return "Samostojni podjetnik (s.p.)";
    case 2:
      return "Družba z omejeno odgovornostjo (d.o.o.)";
    case 3:
      return "Družba z neomejeno odgovornostjo (d.n.o.)";
    case 4:
      return "Komanditna družba (k.d.)";
    case 5:
      return "Delniška družba (d.d.)";
    case 6:
      return "Komanditna delniška družba (k.d.d.)";
    case 7:
      return "Podružnica";
    case 8:
      return "Gospodarsko interesno združenje";
    case 9:
      return "Socialno podjetništvo";
    case 10:
      return "Invalidsko podjetje ";
    case 11:
      return "Zavod ";
    case 12:
      return "Društvo ";
    case 13:
      return "Ustanova ";
    case 14:
      return "Kmetijsko gospodarstvo ";
    case 15:
      return "Mladinska organizacija ";
    case 16:
      return "Status kmeta ";
    case 17:
      return "Zadruga ";
    case 18:
      return "Status samostojnega novinarja ";
    case 19:
      return "Status samozaposlenega v kulturi ";
    case 20:
      return "Status zasebnega raziskovalca ";
    case 21:
      return "Kratkotrajno delo ";
    case 22:
      return "Osebno dopolnilno delo ";
    case 23:
      return "Začasno ali občasno delo upokojencev ";
    case 24:
      return "Sobodajalstvo ";
    default:
      return "Pravna oblika ni na voljo!";
  }
};

export default legalFromParser;
