import React from "react";
import "../Assets/Styles/Pages/Contact.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
const Contact = () => {
  return (
    <>
      <Header />
      <div className="contact-page">
        <a href="mailto:info@odkrij.si" className="contact-page-box">
          <span className="contact-title">Splošna vprašanja</span>
          <span>
            <a className="contact-mail-link" href="mailto:info@odkrij.si">
              info@odkrij.si
            </a>
          </span>
        </a>
        <a href="mailto:billing@odkrij.si" className="contact-page-box">
          <span className="contact-title">Vprašanja o računu</span>
          <span>
            <a className="contact-mail-link" href="mailto:billing@odkrij.si">
              billing@odkrij.si
            </a>
          </span>
        </a>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
