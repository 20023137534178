import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import CompanyLogin from "./Pages/CompanyLogin";
import Company from "./Pages/Company";
import CompanyAdministration from "./Pages/CompanyAdministration";

import NotFoundPage from "./Pages/ErrorPages/NotFoundPage";
import EditOffer from "./Pages/EditOffer";
import AddNewOffer from "./Pages/AddNewOffer";
import RemoveOffer from "./Pages/RemoveOffer";
import UserLogin from "./Pages/UserLogin";
import UserRegister from "./Pages/UserRegister";
import ForgottenPassword from "./Pages/ForgottenPassword";
import CompanyViaUrl from "./Pages/CompanyViaUrl";
import DisplayCompanies from "./Pages/DisplayCompanies";
import UserAdministration from "./Pages/UserAdministration";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Contact from "./Pages/Contact";
import AboutUs from "./Pages/AboutUs";
import OdkrijOffer from "./Pages/OdkrijOffer";
import FinishOrder from "./Pages/FinishOrder";
import ViewCart from "./Pages/ViewCart";
import OrderService from "./Pages/OrderService";

const router = createBrowserRouter([
  { path: "/", element: <App />, errorElement: <NotFoundPage /> },
  {
    path: "/prijavaPodjetja",
    element: <CompanyLogin />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/upravljanjePodjetja",
    element: <CompanyAdministration />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/upravljanjePonudbe",
    element: <EditOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/dodajPonudbo",
    element: <AddNewOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odstraniPonudbo",
    element: <RemoveOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/prijavaUporabnika",
    element: <UserLogin />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/registracijaUporabnika",
    element: <UserRegister />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/pozabljenoGeslo",
    element: <ForgottenPassword />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/podjetje",
    element: <DisplayCompanies />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/podjetje/:imePodjetja",
    element: <CompanyViaUrl />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/upravljanjeUporabnika",
    element: <UserAdministration />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/politikaZasebnosti",
    element: <PrivacyPolicy />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/kontakt",
    element: <Contact />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odkrijNas",
    element: <AboutUs />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odkrijPonudbo",
    element: <OdkrijOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/zakljuciNarocilo",
    element: <FinishOrder />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odkrijVozicek",
    element: <ViewCart />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/narociloStoritve/:imeNarocila",
    element: <OrderService />,
    errorElement: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
