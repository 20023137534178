import axios from "axios";

const deleteUser = (param) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        process.env.REACT_APP_DELETE_USER,
        { params: { param } },
        { withCredentials: true }
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export default deleteUser;
