export const ErrorCodesParser = (value) => {
  switch (value) {
    case 600:
      return "NI DEFINIRANO";
    case 601:
      return "NIČELNA VREDNOST";
    case 602:
      return "NEVELJAVNA VREDNOST";
    case 603:
      return "NEUSPEŠNO";
    case 604:
      return "DUPLIKAT NAJDEN";
    case 605:
      return "NI MOGOČE VZPOSTAVITI POVEZAVE Z STREŽNIKOM";
    case 606:
      return "POIZVEDBA NE USPEŠNA";
    case 607:
      return "NEVELJAVEN FORMAT";
    case 608:
      return "UPORABNIK ŽE PRIJAVLJEN";
    case 609:
      return "POTREBNA PRIJAVA";
    case 610:
      return "NEVELJAVNA VLOGA UPORABNIKA";
    case 611:
      return "POŠILJANJE E-POŠTE NI USPELO";
    case 612:
      return "INTERNA NAPAKA";
    case 613:
      return "INTERNA NAPAKA";
    case 614:
      return "MANJKAJOČI PODATKI O PODJETJU";
    default:
      return "NAPAKA";
  }
};
