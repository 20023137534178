import React, { useEffect, useRef, useState } from "react";
import {
  BsBoxFill as ProductIcon,
  BsWrench as ServiceIcon,
} from "react-icons/bs";

import validateCompanyData from "../Components/utils/ValidateCompanyData";

import offerCategoryParser from "../Components/utils/OfferCategoryParser";

import InputBox from "../Components/InputBox";
import DropDownMenu from "../Components/DropDownMenu";
import TextArea from "../Components/TextArea";
import ImageInput from "../Components/ImageInput";
import ErrorWindow from "../Components/ErrorWindow";
import Loader from "../Components/Loader";
import insertNewCompany from "../Services/insertNewCompany";
import insertCompanyImages from "../Services/insertCompanyImages";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import { DIACRITICS, SPECIAL_CHARS } from "../Constants/RegexPatterns";
import AddProduct from "../Components/AddProduct";
import AddService from "../Components/AddService";
import validateOfferData from "../Components/utils/ValidateOfferData";
import insertNewOffer from "../Services/insertNewOffer";
import UpdateOfferWindow from "../Components/UpdateOfferWindow";
import { useNavigate } from "react-router-dom";

import "../Assets/Styles/Pages/CompanyLogin.css";
import "../Assets/Styles/Config/Icons.css";
import CheckBox from "../Components/CheckBox";
import Header from "../Components/Header";
import UserInvoiceReciever from "../Components/UserInvoiceReciever";

const CompanyLogin = () => {
  const [charCount, setCharCount] = useState(0);
  const [company, setCompany] = useState({
    legal: {
      taxPayer: false,
      invoiceReceiver: false,
      binding: false,
      bindingType: null,
    },
  });
  const [invoiceRecieverStatus, setInvoiceRecieverStatus] = useState(true);

  const [imageName, setImageName] = useState(undefined);

  const [flagsObj, setFlagsObj] = useState({
    intro: false,
    logo: false,
    productsImg: false,
    service: false,
    product: false,
  });

  const formData = useRef(new FormData());

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // #region HANDLE INVOICE RECIEVER WINDOW
  const handleInvoiceRecieverWindow = (status) => {
    setInvoiceRecieverStatus(status);
  };

  // #region CHANGE INPUT
  const changeInput = (e, datatype) => {
    if (datatype === "image") {
      const type = e.name.split("-");
      if (flagsObj[type[type.length - 1]]) {
        let tempArr = formData.current.getAll("images");

        formData.current.delete("images");

        if (type[type.length - 1] === "intro") {
          tempArr[1] = e;
        }

        if (type[type.length - 1] === "logo") {
          tempArr[0] = e;
        }

        if (type[type.length - 1] === "productsImg") {
          tempArr[2] = e;
        }

        for (const item of tempArr) {
          formData.current.append("images", item);
        }
      } else {
        setFlagsObj({ ...flagsObj, [type[type.length - 1]]: true });
        formData.current.append("images", e);
      }
    } else if (
      e.target.name === "invoiceReceiver" ||
      e.target.name === "taxPayer" ||
      e.target.name === "binding"
    ) {
      setCompany({
        ...company,
        [datatype]: {
          ...company[datatype],
          [e.target.name]: e.target.checked,
        },
      });
    } else {
      setCompany({
        ...company,
        [datatype]: {
          ...company[datatype],
          [e.target.name]: e.target.value,
        },
      });
      if (datatype === "description") {
        setCharCount(e.target.value.length);
      }
    }
  };

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const [companyId, setCompanyId] = useState(undefined);
  // #region ERROR WINDOW
  const handleErrorWindow = () => {
    setError(false);
    if (errorTitle === "POTREBNA PRIJAVA") {
      navigate("/prijavaUporabnika");
    }
  };
  // #region SUBMIT DATA
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const { errorFound, errorText, errorTitle } = validateCompanyData(company);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);
    setIsLoading(false);
    if (!errorFound) {
      try {
        const companyResponse = await insertNewCompany(company);
        setCompanyId(companyResponse.data.id);
        setImageName(`${companyResponse.data.id}-${company.legal.taxNumber}`);
        if (companyResponse.status === 200) {
          setInvoiceRecieverStatus(company.legal.invoiceReceiver);
          setIsLoading(false);
          setShowImages(true);
          setShowOffer(false);
          setLogoImg(true);
        } else {
          setIsLoading(false);
          setError(true);
          setErrorText(companyResponse?.data?.message);
          setErrorTitle(ErrorCodesParser(companyResponse?.data?.errorCode));
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    }
  };
  // #region SUBMIT IMAGES
  const submitImages = async () => {
    setIsLoading(true);
    if (!imageName) {
      setError(true);
    }

    if (formData.current.getAll("images").length !== 3) {
      setIsLoading(false);
      setError(true);
      setErrorText(
        "V obrazcu ste pozabili vnesti slike podjetja preverite podatke ali kontaktirajte našo podporno ekipo."
      );
      setErrorTitle("MANJKAJO SLIKE PODJETJA");
      return;
    }
    try {
      await insertCompanyImages(formData.current);
      setShowOffer(true);
      setShowImages(false);
      setIsLoading(false);
      setLogoImg(false);
      setIntroImg(false);
      setProductsImg(false);
      formData.current.delete("images");
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      setError(true);
      setErrorText(err.response?.data?.message);
      setErrorTitle(ErrorCodesParser(err.response?.data?.errorCode));
    }
  };

  const [companyOffer, setCompanyOffer] = useState({});
  const [companyOfferArr, setCompanyOfferArr] = useState({
    service: [],
    product: [],
  });
  const [charCountOffer, setCharCountOffer] = useState(0);
  const [charCountReturn, setCharCountReturn] = useState(0);
  const [isCheckedWarranty, setIsCheckedWarranty] = useState(false);
  const [isCheckedReturn, setIsCheckedReturn] = useState(false);
  const [imageNameOffer, setImageNameOffer] = useState(undefined);

  // #region OFFER INPUT
  const changeOfferInput = (e, datatype) => {
    if (datatype === "image") {
      const type = e.name.split("-");

      if (flagsObj[type[2]]) {
        let images = formData.current.getAll("images");
        formData.current.delete("images");

        images.pop();

        images.push(e);

        for (const image of images) {
          formData.current.append("images", image);
        }
      } else {
        setFlagsObj({ ...flagsObj, [type[2]]: true });
        formData.current.append("images", e);
      }
    } else if (datatype === "flags") {
      setCompanyOffer({
        ...companyOffer,
        [datatype]: {
          ...companyOffer[datatype],
          [e.target.name]: e.target.checked,
        },
      });
      if (e.target.name === "return") {
        setIsCheckedReturn(!isCheckedReturn);
      }

      if (e.target.name === "warranty") {
        setIsCheckedWarranty(!isCheckedWarranty);
      }
    } else {
      if (e.target.name === "name") {
        const sanitizedName = e.target.value
          .toLowerCase()
          .replace(DIACRITICS, "")
          .replace(SPECIAL_CHARS, "");
        setImageNameOffer(`${companyId}-${sanitizedName}`);
      }

      setCompanyOffer({
        ...companyOffer,
        [datatype]: {
          ...companyOffer[datatype],
          [e.target.name]: e.target.value,
        },
      });
      if (e.target.name === "text") {
        setCharCountOffer(e.target.value.length);
      }
      if (e.target.name === "returnConditions") {
        setCharCountReturn(e.target.value.length);
      }
    }
  };
  const [addProductWindow, setAddProductWindow] = useState(false);
  const [addServiceWindow, setAddServiceWindow] = useState(false);

  const [oldImageName, setOldImageName] = useState(undefined);

  // #region ADD OFFER
  const addOffer = (offertype) => {
    if (!flagsObj[offertype]) {
      setError(true);
      setErrorText(`Vašemu obrazcu manjkajo nekateri podatki!`);
      setErrorTitle("SLIKA PONUDBE");
      return;
    }

    for (const offerArr in companyOfferArr) {
      for (const offer of companyOfferArr[offerArr]) {
        if (offer.basic.name === companyOffer.basic.name) {
          setError(true);
          setErrorText(`Ponudba z enakimi podatki že obstaja`);
          setErrorTitle("DUPLIKAT PONUDBE");
          setOldImageName(
            `${companyId}-${offer.basic.name
              .toLowerCase()
              .replace(DIACRITICS, "")
              .replace(SPECIAL_CHARS, "")}-${offertype}`
          );
          return;
        }
      }
    }

    if (oldImageName) {
      const images = formData.current.getAll("images");

      const newImageName = `${companyId}-${companyOffer.basic.name
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offertype}`;

      let imageIndex = -1;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          if (imageIndex === -1) {
            imageIndex = index;
            return image;
          }

          const tempFile = new File([image], newImageName, {
            type: image.type,
            lastModified: new Date(),
          });
          return tempFile;
        }
        return image;
      });
      formData.current.delete("images");
      for (const image of newimagesArr) {
        formData.current.append("images", image);
      }
    }

    //TODO: PREVERI ČE SO VSI PODATKI

    setCompanyOfferArr((prevState) => ({
      ...prevState,
      [offertype]: [...prevState[offertype], companyOffer],
    }));

    setFlagsObj({ ...flagsObj, [offertype]: false });

    setIsCheckedReturn(false);
    setIsCheckedWarranty(false);
    setAddProductWindow(false);
    setAddServiceWindow(false);
    setCompany(undefined);
    setOldImageName(undefined);
  };

  // #region DELETE OFFER
  const deleteOffer = (deleteOffer, offertype) => {
    let images = formData.current.getAll("images");

    formData.current.delete("images");

    let deleteImageName = deleteOffer.basic.name
      .toLowerCase()
      .replace(DIACRITICS, "")
      .replace(SPECIAL_CHARS, "");

    deleteImageName = `${companyId}-${deleteImageName}-${offertype}`;

    const newImages = images.filter((image) => image.name !== deleteImageName);

    const newOffer = companyOfferArr[offertype].filter(
      (offer) => offer.basic.name !== deleteOffer.basic.name
    );

    for (const image of newImages) {
      formData.current.append("images", image);
    }

    setCompanyOfferArr({
      ...companyOfferArr,
      [offertype]: newOffer,
    });
  };

  // #region SUBMIT OFFER
  const submitOffer = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const { errorFound, errorText, errorTitle } =
      validateOfferData(companyOfferArr);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);
    if (
      formData.current.getAll("images").length !==
      companyOfferArr.service.length + companyOfferArr.product.length
    ) {
      setIsLoading(false);
      setError(true);
      setErrorText(
        "V obrazcu ste pozabili vnesti slike ponudbe preverite podatke ali kontaktirajte našo podporno ekipo."
      );
      setErrorTitle("MANJKAJO SLIKE PODJETJA");
      return;
    }
    if (!errorFound && companyId) {
      try {
        const response = await insertNewOffer(companyOfferArr, companyId);
        if (response.status === 200) {
          if (response?.data?.affectedRows > 0) {
            await insertCompanyImages(formData.current);
          }

          if (response?.data?.duplicateArr.length > 0) {
            let text = "";
            for (const item of response.data.duplicateArr) {
              text += item + ", ";
            }
            const displayText = text.slice(0, -2);
            setIsLoading(false);
            setError(true);
            setErrorText(`Ponudbe: (${displayText}) že obstajajo.`);
            setErrorTitle("DUPLIKAT PONUDBE");
          }
          navigate("/", { replace: true });
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    }
  };

  const [isOpenUpdateOfferWindow, setIsOpenUpdateOfferWindow] = useState(false);
  // #region UPDATE OFFER WINDOW
  const handleUpdateOfferWindow = (offerId) => {
    setIsOpenUpdateOfferWindow(!isOpenUpdateOfferWindow);
  };

  const saveOffer = (
    newOffer,
    newImage,
    newImageUrl,
    offerType,
    oldOfferName,
    companyId
  ) => {
    //TODO: VALIDATE DATA

    if (newOffer.basic.name !== oldOfferName) {
      const images = formData.current.getAll("images");
      const oldImageName = `${companyId}-${oldOfferName
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;
      const newImageName = `${companyId}-${newOffer.basic.name
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          const tempFile = new File([image], newImageName, {
            type: image.type,
            lastModified: new Date(),
          });
          return tempFile;
        }
        return image;
      });
      formData.current.delete("images");
      for (const image of newimagesArr) {
        formData.current.append("images", image);
      }
    }

    if (newImage) {
      const images = formData.current.getAll("images");

      const oldImageName = `${companyId}-${oldOfferName
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          return newImageUrl.get("images");
        } else {
          return image;
        }
      });

      formData.current.delete("images");

      for (const image of newimagesArr) {
        formData.current.append("images", image);
      }
    }

    const indexOfOldOffer = companyOfferArr[offerType].findIndex(
      (offer) => offer.basic.name === oldOfferName
    );

    const newOfferArr = companyOfferArr[offerType].map((itemOfArray, index) => {
      if (index === indexOfOldOffer) {
        return newOffer;
      } else {
        return itemOfArray;
      }
    });
    setCompanyOfferArr({
      ...companyOfferArr,
      [offerType]: newOfferArr,
    });

    setIsOpenUpdateOfferWindow(!isOpenUpdateOfferWindow);
  };

  const [showImages, setShowImages] = useState(false);
  const [showOffer, setShowOffer] = useState(false);

  const [logoImg, setLogoImg] = useState(false);
  const [introImg, setIntroImg] = useState(false);
  const [productsImg, setProductsImg] = useState(false);
  // #region JSX
  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && isLoading && <Loader />}

      <Header />

      {!showImages && !showOffer && (
        <form onSubmit={handleSubmit}>
          <div className="company-data-form">
            <div className="grid-item" id="grid-item-1">
              <InputBox
                label="Kratko ime: "
                id="input-bar-1"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="shortName"
                datatype="basic"
                placeholder="Vnesi kratko ime..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="Popolno ime: "
                id="input-bar-2"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="longName"
                datatype="basic"
                placeholder="Vnesi popolno ime..."
                type="text"
                onchange={changeInput}
              />
            </div>
            <div className="grid-item" id="grid-item-2">
              <InputBox
                label="Telefonska številka: "
                id="input-bar-10"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="phoneNumber"
                datatype="contact"
                placeholder="Vnesi telefonsko številko..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="E-naslov: "
                id="input-bar-11"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="email"
                datatype="contact"
                placeholder="Vnesi E-naslov..."
                type="text"
                onchange={changeInput}
              />
            </div>
            <div className="grid-item" id="grid-item-3">
              <DropDownMenu
                label="Pravna oblika:"
                placeholder="pravna oblika"
                id="input"
                dropdownbox="dropdown-box"
                name="legalForm"
                datatype="legal"
                onchange={changeInput}
                data={[
                  { value: 1, text: "Samostojni podjetnik (s.p.)" },
                  { value: 2, text: "Družba z omejeno odgovornostjo (d.o.o.)" },
                  {
                    value: 3,
                    text: "Družba z neomejeno odgovornostjo (d.n.o.)",
                  },
                  { value: 4, text: "Komanditna družba (k.d.)" },
                  { value: 5, text: "Delniška družba (d.d.)" },
                  { value: 6, text: "Komanditna delniška družba (k.d.d.)" },
                  { value: 7, text: "Podružnica" },
                  { value: 8, text: "Gospodarsko interesno združenje" },
                  { value: 9, text: "Socialno podjetništvo" },
                  { value: 10, text: "Invalidsko podjetje" },
                  { value: 11, text: "Zavod" },
                  { value: 12, text: "Društvo" },
                  { value: 13, text: "Ustanova" },
                  { value: 14, text: "Kmetijsko gospodarstvo" },
                  { value: 15, text: "Mladinska organizacija" },
                  { value: 16, text: "Status kmeta" },
                  { value: 17, text: "Zadruga" },
                  { value: 18, text: "Status samostojnega novinarja" },
                  { value: 19, text: "Status samozaposlenega v kulturi" },
                  { value: 20, text: "Status zasebnega raziskovalca" },
                  { value: 21, text: "Kratkotrajno delo" },
                  { value: 22, text: "Osebno dopolnilno delo" },
                  { value: 23, text: "Začasno ali občasno delo upokojencev" },
                  { value: 24, text: "Sobodajalstvo" },
                ]}
              />
              <InputBox
                label="Matična številka: "
                id="input-bar-7"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                datatype="legal"
                name="registrationNumber"
                placeholder="Vnesi matično številko..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="Davčna številka: "
                id="input-bar-8"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="taxNumber"
                datatype="legal"
                placeholder="Vnesi davčno številko..."
                type="text"
                onchange={changeInput}
              />
              <CheckBox
                label="Davčni zavezanec "
                id="checkbox-1"
                labelclass="label"
                datatype="legal"
                name="taxPayer"
                value="true"
                onchange={changeInput}
              />
              <InputBox
                label="Številka računa (TRR): "
                id="input-bar-9"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="accountNumber"
                datatype="legal"
                placeholder="Vnesi številko računa (TRR)..."
                type="text"
                onchange={changeInput}
              />
              <DropDownMenu
                label="Kategorija podjetja:"
                placeholder="kategorija"
                id="input-2"
                dropdownbox="dropdown-box"
                name="category"
                datatype="legal"
                onchange={changeInput}
                data={[
                  { value: 1, text: "Energetika" },
                  { value: 2, text: "Gradbeništvo" },
                  { value: 3, text: "Nepremičnine" },
                  { value: 4, text: "Proizvodnja" },
                  { value: 5, text: "Izobraževanje" },
                  { value: 6, text: "Kmetijstvo, gozdarstvo in ribištvo" },
                  { value: 7, text: "Kultura" },
                  { value: 8, text: "Transport" },
                  { value: 9, text: "Turizem in gostinstvo" },
                  { value: 10, text: "Trgovina in storitve" },
                  { value: 11, text: "Finančne storitve" },
                  { value: 12, text: "Tehnologija in informacijske storitve" },
                  { value: 13, text: "Industrija" },
                  { value: 14, text: "Poslovne storitve" },
                ]}
              />
              <CheckBox
                label="Račun izstavljen podjetju"
                id="checkbox-2"
                labelclass="label"
                datatype="legal"
                name="invoiceReceiver"
                value="true"
                onchange={changeInput}
              />
            </div>
            <div className="grid-item" id="grid-item-4">
              <InputBox
                label="Ulica: "
                id="input-bar-3"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="street"
                datatype="address"
                placeholder="Vnesi ulico podjetja..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="Kraj: "
                id="input-bar-4"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="city"
                datatype="address"
                placeholder="Vnesi kraj podjetja..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="Pošta: "
                id="input-bar-5"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="post"
                datatype="address"
                placeholder="Vnesi pošto podjetja..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="Občina: "
                id="input-bar-6"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="municipality"
                datatype="address"
                placeholder="Vnesi občino podjetja..."
                type="text"
                onchange={changeInput}
              />
              <DropDownMenu
                label="Regija:"
                placeholder="regija"
                id="input-1"
                dropdownbox="dropdown-box"
                name="region"
                datatype="address"
                onchange={changeInput}
                data={[
                  { value: 1, text: "Gorenjska regija" },
                  { value: 2, text: "Goriška regija" },
                  { value: 3, text: "Jugovzhodna slovenija" },
                  { value: 4, text: "Koroška regija" },
                  { value: 5, text: "Obalno-kraška regija" },
                  { value: 6, text: "Osrednjeslovenska regija" },
                  { value: 7, text: "Podravska regija" },
                  { value: 8, text: "Pomurska regija" },
                  { value: 9, text: "Posavska regija" },
                  { value: 10, text: "Primorsko-notranjska regija" },
                  { value: 11, text: "Savinjska regija" },
                  { value: 12, text: "Zasavska regija" },
                ]}
              />
            </div>
            <div className="grid-item" id="grid-item-5">
              <InputBox
                label="Ime predstavnika: "
                id="input-bar-12"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="representativeName"
                datatype="representative"
                placeholder="Vnesi ime..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="Priimek predstavnika: "
                id="input-bar-13"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="representativeLastName"
                datatype="representative"
                placeholder="Vnesi priimek..."
                type="text"
                onchange={changeInput}
              />
              <InputBox
                label="E-naslov predstavnika: "
                id="input-bar-14"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="representativeEmail"
                datatype="representative"
                placeholder="Vnesi E-naslov..."
                type="text"
                onchange={changeInput}
              />
              <CheckBox
                label="Vezava (24 mesecev -20%) "
                id="checkbox-3"
                labelclass="label"
                datatype="legal"
                name="binding"
                value="true"
                onchange={changeInput}
              />

              {company.legal.binding && (
                <InputBox
                  label="Kupon: "
                  id="input-bar-15"
                  class="input-bar"
                  labelclass="label"
                  inputcontainer="input-container"
                  name="bindingType"
                  datatype="legal"
                  placeholder="Vnesi kupon..."
                  type="text"
                  onchange={changeInput}
                />
              )}
            </div>
            <div className="grid-item" id="grid-item-6">
              <TextArea
                label="Opis podjetja"
                id="textarea"
                name="text"
                rows="10"
                cols="50"
                placeholder="Vnesi opis podjetja..."
                datatype="description"
                char={charCount}
                minChar="200"
                labelclass="label"
                onchange={changeInput}
              />
            </div>
          </div>
          <div className="button-box">
            <input type="submit" className="button-1" value="Naprej" />
          </div>
        </form>
      )}
      {!invoiceRecieverStatus && (
        <UserInvoiceReciever
          handleinvoicerecieverwindow={handleInvoiceRecieverWindow}
          companyid={companyId}
        />
      )}
      {showImages && !showOffer && imageName && invoiceRecieverStatus && (
        <div className="company-images-form">
          {logoImg && !introImg && !productsImg && (
            <div className="image-box">
              <ImageInput
                datatype="image"
                name={`${imageName}-logo`}
                src=""
                classname="loading-image"
                title="Logotip podjetja"
                id="input-img-1"
                onchange={changeInput}
                alt="Logotip podjetja"
                nextbtn="Naprej"
                nextimg={() => {
                  setLogoImg(false);
                  setIntroImg(true);
                  setProductsImg(false);
                }}
                height="50vh"
                width="45%"
              />
            </div>
          )}
          {!logoImg && introImg && !productsImg && (
            <div className="image-box">
              <ImageInput
                datatype="image"
                name={`${imageName}-intro`}
                src=""
                classname="loading-image"
                id="input-img-2"
                onchange={changeInput}
                alt="Začetna slika podjetja"
                nextbtn="Naprej"
                title="Slika na začetni strani"
                backbtn="Nazaj"
                backimg={() => {
                  setLogoImg(true);
                  setIntroImg(false);
                  setProductsImg(false);
                }}
                nextimg={() => {
                  setLogoImg(false);
                  setIntroImg(false);
                  setProductsImg(true);
                }}
                height="70vh"
                width="70%"
              />
            </div>
          )}
          {!logoImg && !introImg && productsImg && (
            <>
              <div className="image-box">
                <ImageInput
                  datatype="image"
                  name={`${imageName}-productsImg`}
                  src=""
                  title="Slika na strani ponudbe"
                  classname="loading-image"
                  id="input-img-3"
                  onchange={changeInput}
                  alt="Slika ponudbe podjetja"
                  nextbtn="Dodaj"
                  backbtn="Nazaj"
                  backimg={() => {
                    setLogoImg(false);
                    setIntroImg(true);
                    setProductsImg(false);
                  }}
                  nextimg={() => {
                    setLogoImg(false);
                    setIntroImg(false);
                    setProductsImg(false);
                  }}
                  submitImgs={submitImages}
                  height="50vh"
                  width="45%"
                />
              </div>
            </>
          )}

          {formData.current.getAll("images").length === 3 &&
            !logoImg &&
            !introImg &&
            !productsImg && (
              <div className="image-status-page">
                <div className="image-status-box-1">
                  <div
                    className="image-status"
                    id="logo-image"
                    onClick={() => {
                      setLogoImg(true);
                      setIntroImg(false);
                      setProductsImg(false);
                    }}
                  >
                    Logotip podjetja
                  </div>
                  <div
                    className="image-status"
                    id="intro-image"
                    onClick={() => {
                      setLogoImg(false);
                      setIntroImg(true);
                      setProductsImg(false);
                    }}
                  >
                    Slika na začetni strani
                  </div>
                </div>
                <div className="image-status-box-2">
                  <div
                    className="image-status"
                    id="productsImg-image"
                    onClick={() => {
                      setLogoImg(false);
                      setIntroImg(false);
                      setProductsImg(true);
                    }}
                  >
                    Slika na strani ponudbe
                  </div>
                </div>
                <button className="button-1" onClick={submitImages}>
                  Dodaj slike
                </button>
              </div>
            )}
        </div>
      )}

      {showOffer && !showImages && (
        <form onSubmit={submitOffer}>
          <div className="company-offer-form">
            <div className="add-icons-box">
              <div>
                <ProductIcon
                  className="add-product-icon"
                  onClick={() => {
                    setAddProductWindow(!addProductWindow);
                    setAddServiceWindow(false);
                    setIsCheckedReturn(false);
                    setIsCheckedWarranty(false);
                    setCharCountOffer(0);
                    setCharCountReturn(0);
                  }}
                />
                {addProductWindow && !addServiceWindow && (
                  <AddProduct
                    changeofferinput={changeOfferInput}
                    charcount={charCountOffer}
                    charcountreturn={charCountReturn}
                    imagename={imageNameOffer}
                    offertype="product"
                    addoffer={addOffer}
                    ischeckedwarranty={isCheckedWarranty}
                    ischeckedreturn={isCheckedReturn}
                    return={isCheckedReturn}
                    warranty={isCheckedWarranty}
                    closewindow={() => {
                      setAddProductWindow(!addProductWindow);
                      setAddServiceWindow(false);
                      setIsCheckedReturn(false);
                      setIsCheckedWarranty(false);
                      setCharCountOffer(0);
                      setCharCountReturn(0);
                    }}
                  />
                )}
              </div>
              <div>
                <ServiceIcon
                  className="add-service-icon"
                  onClick={() => {
                    setAddServiceWindow(!addServiceWindow);
                    setAddProductWindow(false);
                    setIsCheckedReturn(false);
                    setIsCheckedWarranty(false);
                    setCharCountOffer(0);
                    setCharCountReturn(0);
                  }}
                />
                {addServiceWindow && !addProductWindow && (
                  <AddService
                    changeofferinput={changeOfferInput}
                    charcount={charCountOffer}
                    imagename={imageNameOffer}
                    offertype="service"
                    addoffer={addOffer}
                    closewindow={() => {
                      setAddServiceWindow(!addServiceWindow);
                      setAddProductWindow(false);
                      setIsCheckedReturn(false);
                      setIsCheckedWarranty(false);
                      setCharCountOffer(0);
                    }}
                  />
                )}
              </div>
            </div>
            {companyOfferArr.product.length > 0 &&
              companyOfferArr.product.map((value, key) => (
                <div className="offer-list-item" key={key}>
                  <span>
                    <ProductIcon className="offer-list-icon" />
                  </span>
                  <span>{value.basic.name}</span>
                  <span>{offerCategoryParser(value.basic.category)}</span>
                  <span>{value.basic.type}</span>
                  <span>{value.basic.email}</span>
                  <span>{value.basic.price}</span>
                  <div className="button-box-offer-list">
                    <button
                      className="button-1"
                      type="button"
                      onClick={handleUpdateOfferWindow}
                    >
                      UREDI
                    </button>
                    <button
                      type="button"
                      onClick={() => deleteOffer(value, "product")}
                      className="button-2"
                    >
                      Odstrani
                    </button>
                  </div>
                </div>
              ))}
            {companyOfferArr.service.length > 0 &&
              companyOfferArr.service.map((value, key) => (
                <div className="offer-list-item" key={key}>
                  <span>
                    <ServiceIcon className="offer-list-icon" />
                  </span>
                  <span>{value.basic.name}</span>
                  <span>{offerCategoryParser(value.basic.category)}</span>
                  <span>{value.basic.type}</span>
                  <span>{value.basic.email}</span>
                  <span>{value.basic.price}</span>
                  <div className="button-box-offer-list">
                    <button
                      className="button-1"
                      type="button"
                      onClick={handleUpdateOfferWindow}
                    >
                      UREDI
                    </button>
                    <button
                      type="button"
                      onClick={() => deleteOffer(value, "service")}
                      className="button-2"
                    >
                      Odstrani
                    </button>
                  </div>
                  {isOpenUpdateOfferWindow && (
                    <UpdateOfferWindow
                      companyid={companyId}
                      offer={value}
                      offertype="service"
                      images={formData}
                      saveoffer={saveOffer}
                      handleupdateofferwindow={handleUpdateOfferWindow}
                    />
                  )}
                </div>
              ))}
            <input className="button-1" type="submit" value="Dodaj ponudbo" />
          </div>
        </form>
      )}
    </>
  );
};

export default CompanyLogin;
