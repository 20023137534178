import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NUMBER_REGEX } from "../Constants/RegexPatterns";
import getEditOfferData from "../Services/getEditOfferData";
import ErrorWindow from "../Components/ErrorWindow";
import ValidateOfferArr from "../Components/utils/ValidateOfferArr";
import deleteOfferData from "../Services/deleteOfferData";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import Loader from "../Components/Loader";
import OfferCategoryParser from "../Components/utils/OfferCategoryParser";

import {
  BsBoxFill as ProductIcon,
  BsWrench as ServiceIcon,
} from "react-icons/bs";

import "../Assets/Styles/Pages/RemoveOffer.css";

const RemoveOffer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [companyId, setCompanyId] = useState(undefined);
  const [offerType, setOfferType] = useState(undefined);

  useEffect(() => {
    if (!location.state) {
      navigate(-1);
      return;
    }

    const { companyId, offerType } = location.state;
    if (!companyId || !NUMBER_REGEX.test(companyId)) {
      navigate(-1);
      return;
    }

    if (offerType !== "service" && offerType !== "product") {
      navigate(-1);
      return;
    }

    setCompanyId(companyId);
    setOfferType(offerType);
  }, []);

  const [offerArr, setOfferArr] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);

  useEffect(() => {
    if (companyId) {
      getOfferDataFunc();
    }
  }, [companyId]);

  const getOfferDataFunc = async () => {
    setIsLoading(true);
    try {
      const companyIds = [companyId];
      const response = await getEditOfferData(companyIds, offerType);

      if (response.status === 200) {
        setOfferArr(response.data.offerArr);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorText(error?.response?.data?.message);
      setErrorTitle(ErrorCodesParser(error?.response?.data?.errorCode));
    }
  };

  const [offerDeleteArr, setOfferDeleteArr] = useState([]);

  const addToDeleteArr = (offerId, companyId, offerName, offerPrice) => {
    setOfferDeleteArr([
      ...offerDeleteArr,
      {
        ID: offerId,
        companyId: companyId,
        offerName: offerName,
        offerPrice: offerPrice,
        offerType: offerType,
      },
    ]);

    const newOffer = offerArr.filter((offer) => offer.name !== offerName);

    setOfferArr(newOffer);
  };

  const deleteOffer = async () => {
    if (offerDeleteArr.length === 0) {
      navigate(-1);
      return;
    }
    setIsLoading(true);
    const { errorFound, errorText, errorTitle } =
      ValidateOfferArr(offerDeleteArr);
    if (errorFound) setIsLoading(false);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);

    if (!errorFound) {
      try {
        await deleteOfferData(offerDeleteArr);
        setIsLoading(false);
        navigate(-1);
      } catch (err) {
        console.log(err);
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    }
  };

  const handleErrorWindow = () => {
    setError(!error);
  };

  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && isLoading && <Loader />}
      <div className="offer-list-container">
        {offerArr &&
          offerArr.map((offer, index) => (
            <div key={index} className="remove-offer-list-box">
              {offerType === "product" ? (
                <ProductIcon className="offer-list-icon" />
              ) : (
                <ServiceIcon className="offer-list-icon" />
              )}
              <span>{offer.name}</span>
              <span>{offer.price}</span>
              <span>{offer.type}</span>
              <span>{OfferCategoryParser(offer.category)}</span>
              <span>{offer.time}</span>
              <button
                className="button-2"
                onClick={() =>
                  addToDeleteArr(
                    offer.ID,
                    offer.companyId,
                    offer.name,
                    offer.price
                  )
                }
              >
                Zbriši
              </button>
            </div>
          ))}
        <button className="button-1" onClick={deleteOffer}>
          SHRANI
        </button>
      </div>
    </>
  );
};

export default RemoveOffer;
