import React from 'react'
import Cropper from 'react-easy-crop'

const ImageCropper = (props) => {
  return (
    <Cropper 
        image={props.image}
        crop={props.crop}
        aspect={props.aspect}
        zoom={props.zoom}
        onCropChange={props.oncropchange}
        onZoomChange={props.onzoomchange}
        onCropComplete={props.oncropcomplete}
    />
  )
}

export default ImageCropper