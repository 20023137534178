import React, { useEffect } from "react";
import "../Assets/Styles/Components/ErrorWindowStyle.css";
import "../Assets/Styles/Components/ButtonStyle.css";

const ErrorWindow = (props) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  });
  return (
    <div className="Error-page">
      <div className="Error-box">
        <h2 className="Error-title">{props.title || "NAPAKA"}</h2>
        <div>
          <p className="Error-text">
            {props.text || "Prišlo je do napake, poskusite znova ali kontaktirajte našo podporno ekipo"}
          </p>
        </div>
        <button onClick={props.onclick} className="button-1">
          {props.btntext || "ZAPRI"}
        </button>
      </div>
    </div>
  );
};

export default ErrorWindow;
