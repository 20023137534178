import React, { useEffect, useRef, useState } from "react";
import TextArea from "./TextArea";
import InputBox from "./InputBox";
import { DIACRITICS, SPECIAL_CHARS } from "../Constants/RegexPatterns";
import ImageInput from "./ImageInput";

import { BsXLg as CloseIcon } from "react-icons/bs";

import "../Assets/Styles/Components/UpdateOfferWindow.css";

const UpdateOfferWindow = (props) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [imageName, setImageName] = useState(undefined);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(undefined);

  const [imageChangeFlag, setImageChangeFlag] = useState(false);

  const [offerName, setOfferName] = useState(undefined);

  useEffect(() => {
    setImageName(`${props.companyid}-${props.offer.basic.name}`);
  }, [props.companyid, props.offer.basic.name]);

  const [offer, setOffer] = useState({});

  useEffect(() => {
    const tempImageName = `${props.companyid}-${props.offer.basic.name
      .toLowerCase()
      .replace(DIACRITICS, "")
      .replace(SPECIAL_CHARS, "")}-${props.offertype}`;

    setImageName(tempImageName);

    if (!props.imageurl) {
      const images = props.images.current.getAll("images");

      for (const image of images) {
        if (image.name === tempImageName) {
          const imageUrlTemp = URL.createObjectURL(image);
          setImageUrl(imageUrlTemp);
        }
      }
    } else {
      setImageUrl(props.imageurl);
    }

    setOffer(props.offer);
    setOfferName(props.offer.basic.name);
  }, []);

  const [charCountText, setCharCountText] = useState(0);
  const [charCountReturnCondition, setCharCountReturnCondition] = useState(0);

  const formData = useRef(new FormData());

  const handleInputChange = (e, datatype) => {
    if (datatype === "image") {
      formData.current.delete("images");
      formData.current.append("images", e);

      const imageUrlTemp = URL.createObjectURL(e);

      setImagePreviewUrl(imageUrlTemp);
      setImageName(offer.basic.name);

      setImageChangeFlag(!imageChangeFlag);
    } else {
      setOffer({
        ...offer,
        [datatype]: {
          ...offer[datatype],
          [e.target.name]: e.target.value,
        },
      });
      if (e.target.name === "text") {
        setCharCountText(e.target.value.length);
      }

      if (e.target.name === "returnCondition") {
        setCharCountReturnCondition(e.target.value.length);
      }
    }
  };

  return (
    <div className="update-offer-page">
      <div className="update-offer-box">
        <div className="update-offer-intro-page">
          <ImageInput
            src={imagePreviewUrl ? imagePreviewUrl : imageUrl}
            alt={`Slika ponudbe: ${props.offer.basic.name}`}
            height={"300px"}
            width={"60%"}
            className="offer-image"
            datatype="image"
            name={imageName}
            id="input-img-1"
            onchange={handleInputChange}
            nextbtn="Shrani sliko"
            nextimg={true}
          />
        </div>
        <div className="update-offer-data-page">
          <div className="description-text">
            <TextArea
              id="textarea"
              name="text"
              rows="10"
              cols="50"
              defaultvalue={props.offer.basic.text}
              datatype="basic"
              char={charCountText}
              minchar="200"
              onchange={handleInputChange}
            />
          </div>
          <div className="data-box">
            <div className="row-1">
              <InputBox
                label="Ime:"
                id="input-bar-1"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="name"
                datatype="basic"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.offer.basic.name}
              />
              <InputBox
                label="Cena:"
                id="input-bar-2"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="price"
                datatype="basic"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.offer.basic.price}
              />
              <InputBox
                label="Čas: "
                id="input-bar-3"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="time"
                datatype="basic"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.offer.basic.time}
              />
            </div>
            <div className="row-2">
              <InputBox
                label="E-naslov:"
                id="input-bar-4"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="email"
                datatype="basic"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.offer.basic.email}
              />
              <InputBox
                label="Kategorija:"
                id="input-bar-5"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="category"
                datatype="basic"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.offer.basic.category}
              />
              <InputBox
                label="Vrsta:"
                id="input-bar-6"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="type"
                datatype="basic"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.offer.basic.type}
              />
              {props.offer?.bool?.warranty === 1 &&
                props.offertype === "product" && (
                  <InputBox
                    label="Čas garancije:"
                    id="input-box-7"
                    class="input-bar"
                    labelclass="label"
                    inputcontainer="input-container-multiple"
                    name="warrantyTime"
                    datatype="basic"
                    type="text"
                    onchange={handleInputChange}
                    defaultvalue={props.offer?.basic?.warrantyTime}
                  />
                )}
            </div>
            {props.offer?.bool?.returnItem === 1 &&
              props.offertype === "product" && (
                <div className="return-box">
                  <div className="row-3">VRAČILO IZDELKA:</div>
                  <div className="row-4">
                    <TextArea
                      id="textarea-1"
                      name="returnConditions"
                      rows="5"
                      cols="100"
                      defaultvalue={props.offer.basic?.returnConditions}
                      datatype="basic"
                      char={charCountReturnCondition}
                      minchar="200"
                      onchange={handleInputChange}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="button-box">
          <button
            type="button"
            className="button-1"
            onClick={() =>
              props.saveoffer(
                offer,
                imageChangeFlag,
                formData.current,
                props.offertype,
                offerName,
                props.companyid
              )
            }
          >
            SHRANI
          </button>
          <button
            className="button-2"
            type="button"
            onClick={() => props.handleupdateofferwindow(null)}
          >
            ZAPRI
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateOfferWindow;
