import axios from "axios";
import React from "react";

const changePassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_CHANGE_PASSWORD,
        { oldPassword, newPassword },
        { withCredentials: true }
      )
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default changePassword;
