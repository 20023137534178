/* global BigInt */
import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
  ACCOUNT_NUMBER_REGEX,
  STREET_REGEX,
  POST_REGEX,
  PASSWORD_REGEX,
  DATE_REGEX,
  NUMBER_REGEX,
} from "../../Constants/RegexPatterns";

import { badWords } from "../../Constants/badWords";

const validateCompanyData = (data) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";
  for (const item in data) {
    for (const [key, value] of Object.entries(data[item])) {
      switch (item) {
        case "basic":
          switch (key) {
            case "shortName":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "KRATKO IME PODJETJA";
              }
              break;
            case "longName":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "DOLGO IME PODJETJA";
              }
              break;
            case "ID":
              if (!NUMBER_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                errorTitle = "ID Podjetja";
              }
              break;
            case "joinDate":
              if (!DATE_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0000-00-00 (leto-mesec-dan)";
                errorTitle = "DATUM PRIDRUŽITVE PODJETJA";
              }
              break;
              case "status":
                break;
            default:
              console.log("Unknown basic propery!");
              console.log(value)
              errorFound = true;
              errorText = "Napačen vnos v razdelku splošni podatki podjetja!";
              errorTitle = "NAPAČEN VNOS";
              break;
          }
          break;
        case "address":
          switch (key) {
            case "street":
              if (!STREET_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: Naslov 1A";
                errorTitle = "ULICA PODJEJTA";
              }
              break;
            case "post":
              if (!POST_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0000 Kraj pošte";
                errorTitle = "POŠTA PODJETJA";
              }
              break;
            case "city":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "KRAJ PODJETJA";
              }
              break;
            case "municipality":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "OBČINA PODJETJA";
              }
              break;
            case "region":
              if (!NUMBER_REGEX.test(value)) {
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                errorTitle = "REGIJA PODJETJA";
                errorFound = true;
              }
              break;
            case "country":
              if (containsBadWord(value)) {
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "DRŽAVA PODJETJA";
                errorFound = true;
              }
              break;
            default:
              console.log("Unknown address propery!");
              errorFound = true;
              errorText = "Napačen vnos v razdelku naslov podjejta!";
              errorTitle = "NAPAČEN VNOS";
              break;
          }
          break;
        case "legal":
          switch (key) {
            case "legalForm":
              if (!NUMBER_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                errorTitle = "PRAVNA OBLIKA PODJETJA";
              }
              break;
            case "category":
              if (!NUMBER_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                errorTitle = "KATEGORIJA PODJETJA";
              }
              break;
            case "registrationNumber":
              if (!validatingRegistrationNumber(value)) {
                errorFound = true;
                errorText =
                  "Matična številka podjetja je neveljavna preverite ali ste kje pri vpisu naredili napako";
                errorTitle = "MATIČNA ŠTEVILKA PODJETJA";
              }
              break;
            case "taxNumber":
              if (!validatingTaxNumber(value)) {
                //console.log("Davčna številka je napačna");
                errorFound = true;
                errorText =
                  "Davčna številka podjetja je neveljavna preverite ali ste kje pri vpisu naredili napako";
                errorTitle = "DAVČNA ŠTEVILKA PODJETJA";
              }
              break;
            case "accountNumber":
              if (!ACCOUNT_NUMBER_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: SI56 0123 4567 8901 234, SI56-0123-4567-8901-234, SI56012345678901234";
                errorTitle = "ŠTEVILKA RAČUNA (TRR) PODJETJA";
              }
              if (!validatingAccountNumber(value)) {
                errorFound = true;
                errorText =
                  "Številka računa oz. TRR številka podjetja je neveljavna preverite ali ste kje pri vpisu naredili napako";
                errorTitle = "ŠTEVILKA RAČUNA (TRR) PODJETJA";
              }
              break;
            case "offer":
              //do nothing
              break;
            case "invoiceReceiver":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "PREJEMNIK RAČUNA";
              }
              break;
            case "taxPayer":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "DAVČNI ZAVEZANEC";
              }
              break;
            case "binding":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "VEZAVA";
              }
              break;
            case "bindingType":
              if (value) {
                if (containsBadWord(value)) {
                  errorFound = true;
                  errorText =
                    "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                  errorTitle = "KUPON VEZAVE";
                }
              }
              break;
            default:
              console.log("Unknown legal property!" + value);
              if (containsBadWord(value)) {
                errorFound = true;
                errorText = "Napačen vnos v razdelku pravni podatki podjejta!";
                errorTitle = "NAPAČEN VNOS";
                break;
              }
          }
          break;
        case "contact":
          if (key === "email" && !EMAIL_REGEX.test(value)) {
            errorFound = true;
            errorText =
              "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: janez.novak@odkrij.si";
            errorTitle = "E-NASLOV PODJETJA";
          }

          if (key === "phoneNumber" && !PHONE_NUMBER_REGEX.test(value)) {
            errorFound = true;
            errorText =
              "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 000-000-000, 000 000 000, 000000000";
            errorTitle = "TELEFONSKA ŠTEVILKA PODJETJA";
          }
          break;
        case "representative":
          switch (key) {
            case "representativeEmail":
              if (!EMAIL_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: janez.novak@odkrij.si";
                errorTitle = "E-NASLOV PREDSTAVNIKA PODJETJA";
              }
              break;
            case "representativeName":
              if (containsBadWord(value)) {
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "IME PREDSTAVNIKA PODJETJA";
                errorFound = true;
              }
              break;
            case "representativeLastName":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "PRIIMEK PREDSTAVNIKA PODJETJA";
              }
              break;
            case "representativePassword":
              if (!PASSWORD_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Geslo je v nepravilnem formatu vsebovati mora 1 veliko črko vsaj 8 znakov, vsaj 1 poseben znak in vsaj 1 številko";
                errorTitle = "GESLO PREDSTAVNIKA PODJETJA";
              }
              break;
            default:
              console.log("Unknown input in representative section");
              if (containsBadWord(value)) {
                errorFound = true;
                errorText = "Napačen vnos v razdelku predstavnik podjejta!";
                errorTitle = "NAPAČEN VNOS";
                break;
              }
          }
          break;
        case "description":
          if (containsBadWord(value)) {
            errorFound = true;
            errorText =
              "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
            errorTitle = "OPIS PODJETJA";
          }
          break;
        case "image":
          //TODO: sploh nevem kej lahko gledaš tu.
          //console.log(data[item][value]);
          break;
        case "offer":
          //do nothing
          break;
        default:
          console.log(item);
          console.log("Unknown input!");
          errorFound = true;
          errorText = "Napačen vnos v obrazcu za prijavo podjejta!";
          errorTitle = "NAPAČEN VNOS";
          break;
      }
    }
  }
  return { errorFound, errorText, errorTitle };
};

export default validateCompanyData;

const validatingTaxNumber = (value) => {
  let sumOfTaxNumber = 0;

  for (let i = 0; i < 8; i++) {
    sumOfTaxNumber += parseInt(value[i] * (8 - i));
  }

  if (value.length === 8 && /^\d+$/.test(value) && sumOfTaxNumber % 11 === 0) {
    return true;
  } else {
    return false;
  }
};

const validatingRegistrationNumber = (value) => {
  let sumOfRegistrationNumber = 0;

  for (let i = 0; i < 6; i++) {
    sumOfRegistrationNumber += parseInt(value[i] * (value.length - 3 - i));
  }

  let controlNumber = 11 - (sumOfRegistrationNumber % 11);

  controlNumber = controlNumber > 9 ? 0 : controlNumber;

  if (value.length === 10 && controlNumber === parseInt(value[6])) {
    return true;
  } else {
    return false;
  }
};

const validatingAccountNumber = (value) => {
  const sanitizedAccNumber = value.replace(/\s/g, "").replace(/-/g, "");

  if (sanitizedAccNumber.length === 19) {
    let countryCode = BigInt(
      (
        sanitizedAccNumber.toUpperCase().charCodeAt(0) -
        "A".charCodeAt(0) +
        10
      ).toString() +
        (
          sanitizedAccNumber.toUpperCase().charCodeAt(1) -
          "A".charCodeAt(0) +
          10
        ).toString() +
        sanitizedAccNumber[2] +
        sanitizedAccNumber[3]
    );
    let accountNumber = BigInt(
      sanitizedAccNumber.slice(4, sanitizedAccNumber.length)
    );
    if (
      accountNumber <= 999999999999999n &&
      (accountNumber * 1000000n + countryCode) % 97n === 1n
    ) {
      return true;
    }
  } else {
    return false;
  }
};

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase)) {
      return true;
    }
  }

  return false;
};

const isBoolean = (value) => {
  return value === true || value === false || value === 0 || value === 1;
};
