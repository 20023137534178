import React from "react";
import CheckBox from "./CheckBox";

const CategoryFilter = (props) => {
  return (
    <div className="filter-box">
      <CheckBox
        label="Energetika"
        id={`checkbox-${props.index}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="1"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Gradbeništvo"
        id={`checkbox-${props.index + 1}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="2"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Nepremičnine"
        id={`checkbox-${props.index + 2}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="3"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Proizvodnja"
        id={`checkbox-${props.index + 3}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="4"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Izobraževanje"
        id={`checkbox-${props.index + 4}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="5"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Kmetijstvo, gozdarstvo in ribištvo"
        id={`checkbox-${props.index + 5}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="6"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Kultura"
        id={`checkbox-${props.index + 6}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="7"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Transport"
        id={`checkbox-${props.index + 7}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="8"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Turizem in gostinstvo"
        id={`checkbox-${props.index + 8}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="9"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Trgovina in storitve"
        id={`checkbox-${props.index + 9}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="10"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Finančne storitve"
        id={`checkbox-${props.index + 10}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="11"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Tehnologija in informacijske storitve"
        id={`checkbox-${props.index + 11}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="12"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Industrija"
        id={`checkbox-${props.index + 12}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="13"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Poslovne storitve"
        id={`checkbox-${props.index + 13}`}
        labelclass="label"
        datatype="filters"
        name="category"
        value="14"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
    </div>
  );
};

export default CategoryFilter;
