import React from "react";
import CheckBox from "./CheckBox";

const LegalFormFilter = (props) => {
  return (
    <div className="filter-box">
      <CheckBox
        label="Samostojni podjetnik (s.p.)"
        id={`checkbox-${props.index}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="1"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Družba z omejeno odgovornostjo (d.o.o.)"
        id={`checkbox-${props.index + 1}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="2"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Družba z neomejeno odgovornostjo (d.n.o.)"
        id={`checkbox-${props.index + 2}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="3"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Komanditna družba (k.d.)"
        id={`checkbox-${props.index + 3}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="4"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Delniška družba (d.d.)"
        id={`checkbox-${props.index + 4}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="5"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Komanditna delniška družba (k.d.d."
        id={`checkbox-${props.index + 5}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="6"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Podružnica"
        id={`checkbox-${props.index + 6}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="7"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Gospodarsko interesno združenje"
        id={`checkbox-${props.index + 7}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="8"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Socialno podjetništvo"
        id={`checkbox-${props.index + 8}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="9"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Invalidsko podjetje"
        id={`checkbox-${props.index + 9}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="10"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Zavod"
        id={`checkbox-${props.index + 10}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="11"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Društvo"
        id={`checkbox-${props.index + 11}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="12"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Ustanova"
        id={`checkbox-${props.index + 12}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="13"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Kmetijsko gospodarstvo"
        id={`checkbox-${props.index + 13}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="14"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Mladinska organizacija"
        id={`checkbox-${props.index + 14}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="15"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Status kmeta"
        id={`checkbox-${props.index + 15}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="16"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Zadruga"
        id={`checkbox-${props.index + 16}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="17"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Status samostojnega novinarja"
        id={`checkbox-${props.index + 17}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="18"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Status samozaposlenega v kulturi"
        id={`checkbox-${props.index + 18}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="19"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Status zasebnega raziskovalca"
        id={`checkbox-${props.index + 19}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="20"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Kratkotrajno delo"
        id={`checkbox-${props.index + 20}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="21"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Osebno dopolnilno delo"
        id={`checkbox-${props.index + 21}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="22"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Začasno delo upokojencev"
        id={`checkbox-${props.index + 22}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="23"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Sobodajalstvo"
        id={`checkbox-${props.index + 23}`}
        labelclass="label"
        datatype="filters"
        name="legalForm"
        value="24"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
    </div>
  );
};

export default LegalFormFilter;
