import React from "react";

import "../Assets/Styles/Pages/Slovenia.css";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer"
import Header from "../Components/Header"

const Slovenia = () => {
  const navigate = useNavigate();
  const selectRegion = (e) => {
    navigate("/podjetje", {
      state: { region: e.target.getAttribute("region") },
    });
  };

  return (
    <div className="slovenia-page">
      <Header />
      <svg
        className="slovenia-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 800"
      >
        <g>
          <path
            // id="pomurska-regija"
            d="m1098.18,84.85c16.53-4.69-.87,8.96,9.92,9.31,14.83-1.9,21.06,7.25,14.26,20.39-8.53-.84-14.27.69-11.71,10.9,9.05,14.52,35.69,19.96,35.38,39.01l.05.63c17.02-1.25,9.81,11.51,18.26,20.54-7.03,16.63,12.91,18.07,22.74,24.96,4.94,7.49-12.54,2.01-15.95,1.17-6.28-.76-19.23,2.9-12.69-6.9-11.84-3.83-15.07-16.92-29.64-12.8-6.95-7.19,4.79-7.52-11.63-6.57-1.33-2.51-.97-4.8-4.87-4.11-20.01-10.65-11.04,5.39-25.04,6.76-11.68,15.16-17.93-2.03-28.91,16.16l-.54.37c-8.7,3.06-21.25,8.59-26.55-2.26-17.02,3.04-32.22-4.82-48.91-9.44l-.29-.68c-19.77-7.78-14.56-30.93-28.46-40.75-7.34-1.91-22.24-11.15-9.29-16.55,10.21-13.09-17.16-6.01-14.89-20.33-6.96-6.79-21.13-6-24.94-16.61-1.01-7.82-8.44-4.4-12.81-6.89l-.2-1.31c36.64-25.45,100.8,57.75,89.99,11.63-14-20.73-21.85-33.38-16.92-61.01l-.16-1.36c1.8-3.94-8.79-18.28,4.59-13.42,6.96-4.75,11.32-.19,18.64-4.1-.15-3.04,13.75-17.21,14.63-12.04,6.04-9.06,10.24,4.67,17.91,3.13,18.04-4.27,39.89-21.56,56.65-5.42,15.24,10.41,21.41,13.32,13.17,32.2-3.68,1.66-9.52,2.48-8.64,8.07-.17,4.53,5.85,3.9,6.79,6.62-7.86,13.07,6.49,18.64,9.98,30.38l.08.32Z"
            region="8"
            className="map-item"
            onClick={selectRegion}
          />
        </g>

        <g>
          <path
            // id="koroska-regija"
            d="m481.1,223.18c5.11-4,12.63,2.44,8.78-7.15,1.72-2.45,5.77-18.68,10.79-9.83,2.37-8.78,6.92-1.51,11.54-4.27,32.17,3.9,21.03-37.61,40.2-54.92,2.9-8.75,13.47-1.33,19.38-.77,10.92-17.16,18.37-18.91,22.38,2.64,18.07-44.04,66.52-23.59,107.08-22.96l2.56-.64c7.88-6.59,27.3-1.5,39.05-4.41h.08c-1.2,10.06,22.92,18.55,13.44,38.38-8.37-2.08-19.24-8.57-23.58,2.86-8.2,16.97-26.77,45.34-17.75,60.89l-.74,2.05c-23.32,4.45-20.3,14.67-32.2,31.19-31.69-13.13-61.26-28.55-100.2-35.19l-.14.78c-4.32,32.58-47.89,10.58-67.61,18.23-10.8-2.83-17.53-22.81-32.92-16.53l-.14-.35Z"
            region="4"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="savinjska-regija"
            d="m674.69,403.58c-17.1-39.08-59.85-44.04-98.84-42.18v-.02c-.49,0-1.1-.46-1.32-.87,10.25-31.42-46.26-29.31-66.73-33.27l.04-.21c-16.28-3.97-28.35-28.18-35.49-45.01,4.36-19.52-17.58-7.26-26.78-11.59-.4-.19-.77-.41-1.12-.66l.15-1.03c11.15-25.32-9.17-33.35,30.61-37.19.18.31.4.6.57.92,16.94,2.8,23.33,5.42,32.96,20.72,11.87-9.76,29.43-.84,45.41-.93,16,1.96,26.95-13.28,37.97-18.17,16.26,1.31,34.05,20.42,45.56,14.33l.1.18c.27.05.51.22.76.35l.04-.08c63.36,37.41,39.96,8.95,85.11-19.46l.1.15c55.55,31.23-17.67,39.37,68.39,58.58.11.1.17.25.22.39l-.06.04c6.75,10.27.85,28.24,10.89,34.86,31.02-4.93,61.32,13.76,91.72,10.5l.25-.06c10.97,48.18-48.9-3.55-55,23.98,6.8,12.71-7.28,17.03-14.3,25.09,7.06,2.95-4.67,7.12-2.34,10.22,11.82,10.07-2.31,9.52,10.03,24.53.02.12-.02.25-.1.34-37.37,21.42,6.7,32.99-7.29,40.26-10.77-14.32-14.52,4.05-23.69-22.3.01-.3-.01-.6-.05-.89l.06-.16c-25.83-9.25-59.93-10.1-88.79-6.67l.02.17c-.08.05-.18.09-.27.12v.02c-9.71,3.49-12.01-12.31-23.51-6.53-23.87-5.6-25.79,5.99-15.77-17.58l.15.03c.07-.26.08-.54.09-.81l.25-.11Z"
            region="11"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="zasavska-regija"
            d="m648.73,417.8c-10.57-3.07-19.86-.32-21.64,11.68-3.24,2.08-5.6,4.75-4.38,8.91-2.25,7.15,12.9,17.87,2.82,19.8-35.39,15.16-10.28,8-22.35,23.73-32.67-11.76-22.82-7.51-26.76-32.5l.13-.09c-24.73-35.45-27.01-1.84-52.54-7.89-7.59-4.08-11.75-13.71-21.91-10.12l-.16-.47c9.12-11.43,41.71-17.85,43.04-37.69-10.17-18.18,18.94-5.7,23.56-25.33l.06-.13c7.85,3.5,16.69,7.55,26.27,3.46,13.26.76,26.15,4.89,39.97,4.64l2.29.22c5.5,3.4,3.92,13.38,12.74,13.17,6.83,1.02,13.76,12.34,13.88,18.06-8.13-1.77-18.01-1.62-14.73,9.55l-.29,1Z"
            region="12"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="posavska-regija"
            d="m839.63,423.69l.83-.19c4.03-1.16,3.29,5.95,6.23,6.86,7.76-2.33,10.55,7.11,18.58,7.03,15.71,7.5-8.46,26.67-.82,54.56l-.73.56c6.88,11.61-3.08,20.63-9.9,28.28,9.53,15.76-7.3,15.93,10.46,25.91,3.89,7.84-14.97,7.02-19.21,11.48-6.09,13.18-19.95-6.76-29.19-8.66-5.54.23-3.8,13.09-10.66,3.7-5.74-4.84-2.24,4.53-4.89,7.08-2.62,3.75-13.62,6.74-15.22.69-18.16-1.05,6.92,25.97-28.41,16.66l-.59.44c-28.15-38.84,19.07-33.45-8.42-46.25-23.33-10.1-4.82-18.52-18.18-34.72-22.6-31.46-47.35,26.07-66.5-14.85-.04-5.2,7.1-16.92-2.77-16.57l-1.17.38c-16.62,6.99-20.56-16.43-28.13-26.13l.06-.05c6.19-1.76,4.65-12.85,9.79-11.89,20.26,5.05,46.01-4.91,61.57,12,22.86-5.89,48.22.68,73.03-2.46l.07.57c28.41,1.39,16.63,23.86,38.26,24.89-11.81,27.21,26.14,2.05,33.42-7.13,1.26-23.19-37.52,1.13-7.51-32.19Z"
            region="9"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="jugovzhodna-regija"
            d="m551.56,578.68c-.22-8.75,6.24-18.47.53-26.06,2.49-3.27,8.86-2.04,8.79-8.47-5.61-25.34-.48-38.06,18.3-53.56,5.7-12.79,34.38,19,37.44-11.64l-.04-.49c.87-3.33.45-6.24,4.99-5.3,2.95-.61,3.02-5.89,6.3-5.88,14.29,4.74,3.54-5.51,16.73,10.94l.43-.2c13.07,7.06,22.53,41.73,47.6,28.01,18.5,9.78,31.68-29.59,32.44,17.67-.43,8.74,12.84,7.06,13.66,16.72-6.19,10.72-2.08,33.47,9.53,40.94-7.66,14.46-64.67,24.5-44.02,42.95,4.34,7.36.68,15,10.07,5.55,2.83.01,3.64,3.81,3.72,6.64,6.47,18.42,2.85-14.2,11.37-14.2,9.95.71,4.85,13.29,3.43,19.4,5.42-.4,8.19-8.06,10.8,1.77-1.51,6.6,16.07,21.74.05,16.53-6.77-3.09-18.84-5.4-11.04,4.65-6.82-2.43-15.01-.32-19.82,5.09,6.22,5.18-2.76,11.53-8.58,10.96,13.04,7.35,3.42,24.3,10.98,36.33,16.91,30.1,46.34,9.41,16.69,39.26-14.42,12.11-39.46-15.3-45.03,17.13-5.79,8.8-9.03-3.06-14.02-2.01-.67,6.19-7.75,11.14-13.14,6.42-11.99-13.65-24.89-25.68-37.62-37.24-16.83,11.46-32.33-10.45-47.18-18.99l-.03-.3c-12.68,1.37-1.77,21.26-8.81,27.93-9.03.31-28.54,15.21-33.68,5.4l.17-.09c-9.17-37.06-62.7-24.72-45.52-49.16,4.14-16.73-30.36-22.3-36.92-36.45,2.43-14.12,9.93-18.82-6.9-21.78l-.06-.73c-22.43-56.23,6.91-10.03,5.57-44.7-3.42-11.87-9.8-19.46,7.77-19.02,16.12-1.59,28.01-12.73,42.77,1.85l.15-.08c9.13,17.38,52.8,63.5,43.6,15.64-22.51-18.63-5.62-12.81,8.83-20.89h0s-.3-.54-.3-.54Z"
            region="3"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="osrednjeslovenska-regija"
            d="m350.59,420.69c10.57-2.14,8.34-13.66,10.85-21.76,4.66-2.77,11.91,3.84,13.97-5.04,1.48-9,15.93-4.76,12.53-15.97,11.28-18.54,24.83,8.85,47.7-30.54l-.17-.43c18.19-6.85,8.65-21.87,2.57-34.36-.57-.51-1.08-1.1-1.5-1.75l-.26-.42c-1.23-4.73,3.12-6.49,5.24-9.62,1.69-5.85-12.63-23.1.27-21.32,14.15,3.21,19.55-2.16,22.06,15.08,9.04,7.05,10.04,17.22,16.86,24.64,7.78,4.1,7.08,16.07,18.22,17.38v.05c24.01,4.79,87.43,2.88,51.98,35.23-7.95,1.87-22.55-2.34-22.63,9.84l-.41.23c4.28,5.83,13.93,16.45.36,17.65-10.52,12.22-29.45,13.68-37.92,27.83,7.06,16.45,34.8,26.96,52.48,23.18,6.81.02,12.67-13.91,17.64-4.47,12.93,3.54,2.8,19.33,9.25,27.84,8.09,6.93,1.96,13.01-4.98,17.53l-.08-.12c-13.8-.18-12.1,16.56-15.17,26.78l.32.1c-1.56,4.98-2.81,9.62,1.53,22.76-1.99,2.35-8.55.14-9.4,5.09l-.39.22c10.41,48.48-13.09,11.78-18.09,38.02-2.1,10.5,5.82,16.39,12.27,22.26-4.98,10.58-17.86-17.03-23.34-22.09l-.16-.87c-38.57-35.43-34.9-6.21-70.89-9.34-7.16-8.37-15.85-15.5-22.56-24.16-3.39-4.86-.66-17.6-9.12-16.94l-.08-.16c-23.73,12.16-43.6-.72-65.07-10.1l-.91.6c-32.72,25.43,7.15,20.62-60.62,8.24-10.17-9.47-11.8-23.9-6.68-36.42l.39-.2c-4.5-8.43-.99-11.69,5.43-16.77.7-2.28.6-7.73,4.3-6.43l.26.35c25.19-19.51,22.94-61.3,63.93-51.7l.02.08Z"
            region="6"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="goriska-regija"
            d="m103.7,376.96l-.16-.03c5.1-24.97-28.25-.31-35.75-15.76-2.6-13.04-18.05-7.3-25.43-13.71-16.33-7.43-14.6,13.43-30.1,6.07-3.41-4.5,1.79-9.9,4.08-13.7-10.6-6.97-6.54-23.54-15.24-30.24-3.85-7.88,12.09-3.19,12.99-9.39-1.42-7.88,4.58-8.14,9.88-11.52-4.83-30.83,14.41-10.48,29.83-28.94,26.89-4.4,22.76-43.53,56.61-29.52l.05.04c-13.37,16.15,15.16,13.31,24.6,14.48,20.67,2.1,36.27,27.64,10.34,36.16-61.99,47.15,43.83,81.38,79.71,74.36-16.21,20.07,14.81,24.24,24.76,30.95-13.14,11.68-14.2,30.32-13.24,46.87l.37.81c14.58,8.54,21.3,23.94,35.3,34.49,1.62,8.04-13.71,11.18-7.43,20.23-5.75,19.36.08,26.28,5.31,43.53-.87,5.02-5.54,8.47-10.18,10.56-28.45,5.72-3.35,23.05-17.41,40.06-22.54,1.92-40.78-7.72-53.55-25.49-22.27-3.47-41.56-15.97-62.32-20.12-23.97,4.77-6.76,31.51-51.86,18.2l-.12-.08c-21.15-13.73,44.12-83.2,12.35-78.93-6.94,2.17-12.37-9.32-16.04,2.26-9.27,16.25-40.62-5.97-36.13-19.25,4.97-3.33,16.03-14.68,7.75-19.32,10.63-22.77,54.98-33.27,61.03-63.07Z"
            region="2"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="primorsko-notranjska-regija"
            d="m275.73,544.6c1.29-.6,2.68-1.24,4.15-1.9l.94-1.2c22.72-4.36,54.8,29.74,63.04-7.99,18.7,11.17,25.7,14.17,47.2,16.56,5.34.94,13.22-12.99,16.58-4.2.14,18.04,28.7,23.13,19.14,38.03,9.63,6.06,24.95,24.41,2.6,22.49-11.46,10.59,6.11,34.89,9.36,47.11l.36.07c-1.99,11.05-11.47,24.27-21.94,25.65-2.52-.46-2.93,1.96-2.19,4.03,5.1,16.88.21,38.2-19.11,42.44-20.78,9.06-41.97,34.55-61.53,17.33-3.32-4.76-6.45,1.66-9.31-.78-28.44-29.54-2.57,26.94-47.14,5.1l-.73.05c-1.17-14.84,6.62-13.55-6.93-26.57,4.59-13.18,5.46-24.59-2.11-36.04,3.69-4.78,1.62-10.39-1.17-15.57-15.16-27.63,12.5-58.12-20.07-77.16l.33-.55c27.21-33.62-9.94-28.86,28.53-46.9Z"
            region="10"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="obalno-kraska-regija"
            d="m256.08,689.74c13.89,14.2,1.45,19.2,3.55,37.65,2.54,7.06,14.8,3.76,5.99,16.96-15.17,6.3-35.76-34.91-42.36-14.05-4.19,1.02-9.74-10.24-11.66.07.31,5.66,7.22,7.63,9.67,12.11,2.61,6.84-.96,24.07-10.17,19.42-40.84-11.01-15.38,28.55-48.55,5.79-13.93,2.38-10.32-10.13-18.97-14.7-16.23-5.53-34.83,16.43-48.37,5.55-17.13-10.47-26.36.85-25.52-22.84,11.96-14.3-15.4-9.47-8.72-22.64l1.5.64c11.28,2.5,9.33-.41,16.57-3.02,15.02,3.87,25.65-7.27,40.67-3.98,21.92-14.69-7.57-17.09-6.51-23.54,6.69-2.15,16.01-6.33,21.34.42,9.99,7.65,35.83,10.37,41.15-3.18,1.68-9.01,18.2-9.35,17.8-17.86-11.67-15.15-34.03-22.59-30.86-44.43-8.68-4.09-7.94-17.94-19.4-16.06-4.14-4.76-9.65-6.46-15.74-6.94-5.31-3.75-6.53-12.6-13.11-14.44-4.36-.78-8.22-1.21-9.67-5.78-7.17-4.98-26.84,4.66-27.94-8.48l.78-.7c18.74,2.05,43.4,6.28,46.96-17.83,10.29-3.05,17.42,10.7,27.3,12.08,7.87.04,15.24-.05,22.08,4.51,20.69,11.41,35.54,30.13,62.67,31.33l.34-.25c20.02,27.01,23.11,7.43,12.74,41.31,3.43,13.64-.89,32.58,11.69,41.44-2.04,3.41-5.53,6.65-5.03,11.23l-.22.21Z"
            region="5"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="podravska-regija"
            d="m1075.25,245.7c23.65,14.66-8.71,30.66-21.57,15.73-24.35,12.77-19.76-20.44-53.42-1.95-27.05-3.85,11.86,24.07-10.82,28.13-10.22-2.43-8.26,10.12-14.09,14.63-5.46,2.42-13.25-2.2-16.71,4.76-7.27,9.41-19.4,10.33-30.22,8.83-9.89,5.91-18.68,17.4-32.13,13.46l.08-.27c-24.93-8.79-59.69-18.72-87.07-15.41l-.07-.63c-.13-7.97-1.55-15.93-4.17-23.46l.49-.38c-4.9-18.84-42.72-8.29-45.8-26.78,2.54-48.22-66.59-31.19-17.03-90.5l.4-.72c-.32-13.58,10.77,2.81,18.66.55,12.22-11.61,8.71-44.74-9.88-50.32l-.1-.94c12.29-1.55,17.74,14.9,25.34,20.53,36.27-7.96,19.17-13.8,27.81-32.92,26.29-12.98,14.09,2.63,32.79-5.58,6.32-22.87,28.37,1.74,46.74-8.18l.55-.04c2.84,5.9,10.21,4.13,13.17,9.95,4.06,11.82,19.47,7.33,25.09,15.61-1.49,7,4.48,7.78,7.75,11.65-1.41,5.62-1.43,10.92,2.84,15.84l.16.06c27.5,17.36,11.48,12.13,26.72,35.56l.5.59c14.02,18.3,45.99,32.68,66.7,25.45,6.44,16.48,25.38,10.34,33.76,1.19l.57-.1c9.06,9.47,2.84,27.78,12.96,35.66Z"
            region="7"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
        <g>
          <path
            // id="gorenjska-regija"
            d="m429.45,296.37c2.58,5.56-5.55,11.66-.59,18.06-2.51,8.58,4.62,12.91,6.19,19.37-.46.51-.92,1.01-1.36,1.51-15.18,16.9-22.09,25.31-47.38,27.78-9.28,2.38-4.92,15.19-13.09,17.16-4.95,1.34-4.03,7.94-7.52,8.52-5.27-1.71-8.73-.04-11.24,3.15-3.44,4.37-5.09,11.59-7.14,16.82l.84.11c-.37.27-.78.49-1.24.63-49.43-14.8-46.15,53-67.21,49.87-7.05-6.05-19.35-8.36-19.49-20.27l.5-.99c-44.29-21.75,30.02-59.83-15.77-64.51-13.88.97-15.5-10.6-7.94-20.01l.09-.03c-10.37-32.59-82.01,9.7-98.04-41.48l-.18-.54c-4.16-20.8,18.51-23.87,30.33-34.09,9.65-24.58-16.27-41.31-36.37-45.36-5-.52-17.41,7.45-14.12-2.25l-.44-.15c2.47-6.94-1.93-14.87,5.75-18.49-3.85-10.34,4.88-14.07,3.69-22.25-.44-3.2,23.37,2.49,25.51,3.71,6.41,9.32,14.29-.07,20.78-2.53,15.69,4.09,30.95-7.07,45.03,10.29l.53.29c23.95,18.82,29.97-6.76,58.61,15.6,10.77,35.77,23.78,6.63,39.9,20.69l.29.19c10.78-4.24,19.64-7,29.51-.06,12.86-.87,25.17-7.15,38.47-6.34,14.97-1.27-2.62,13.97,13.62,13.87,7-1.86,13.07,7.87,18.05-1.3,10.36-5.71,19.3,15.93,19.67,25.22h-.18c-13.3.7-11.78,18.83-8.04,27.8h-.02Z"
            region="1"
            className="map-item"
            onClick={selectRegion}
          />
        </g>
      </svg>
      <Footer />
    </div>
  );
};

export default Slovenia;
