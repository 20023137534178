import React, { useState, useEffect, useRef } from "react";

import TextArea from "./TextArea";
import InputBox from "./InputBox";

import "../Assets/Styles/Pages/Company.css";

import DropDownMenu from "./DropDownMenu";
import legalFromParser from "./utils/LegalFormParser";
import categoryParser from "./utils/CategoryParser";
import regionParser from "./utils/RegionParser";
import ImageInput from "./ImageInput";

import "../Assets/Styles/Components/UpdateCompany.css";

const UpdateCompanyWindow = (props) => {
  const formData = useRef(new FormData());

  const [company, setCompany] = useState({});

  const [charCount, setCharCount] = useState(0);

  const [imagePreviewObj, setImagePreviewObj] = useState({
    intro: undefined,
    logo: undefined,
    productsImg: undefined,
  });

  const [imageName, setImageName] = useState(undefined);

  // #region SET IMAGE NAME
  useEffect(() => {
    setImageName(`${props.company.basic.ID}-${props.company.legal.taxNumber}`);
  }, [props.company.address, props.company.legal]);

  // #region INPUT CHANGE
  const handleInputChange = (e, datatype) => {
    if (datatype === "image") {
      const name = e.name.split("-");
      const imageUrl = URL.createObjectURL(e);
      formData.current.append("type", e.name);
      formData.current.append("images", e);

      setImagePreviewObj({
        ...imagePreviewObj,
        [name[name.length - 1]]: imageUrl,
      });
    } else {
      setCompany({
        ...company,
        [datatype]: {
          ...company[datatype],
          [e.target.name]: e.target.value,
        },
      });

      if (datatype === "description") {
        setCharCount(e.target.value.length);
      }
    }
  };

  // #region ON LOAD 
  useEffect(() => {
    setCompany(props.company);
  }, []);

  // #region JSX
  return (
    <div className="update-company-page">
      <div className="update-company-intro-page">
        <ImageInput
          src={
            imagePreviewObj.intro
              ? imagePreviewObj.intro
              : `${process.env.REACT_APP_COMPANY_IMAGES_URL}${imageName}-intro.png`
          }
          alt={`Uvodna slika podjetja ${props.company.basic.longName}`}
          height={"80%"}
          width={"90%"}
          className="intro-image"
          datatype="image"
          name={`${imageName}-intro`}
          id="input-img-1"
          onchange={handleInputChange}
          nextbtn="Shrani sliko"
          nextimg={true}
        />
      </div>
      <div className="description-page">
        <div className="description-text">
          <TextArea
            id="textarea"
            name="text"
            rows="10"
            cols="50"
            defaultvalue={props.company.description.text}
            datatype="description"
            char={charCount}
            minChar="200"
            onchange={handleInputChange}
          />
        </div>
        <div className="update-company-image-box">
          <ImageInput
            src={
              imagePreviewObj.logo
                ? imagePreviewObj.logo
                : `${process.env.REACT_APP_COMPANY_IMAGES_URL}${imageName}-logo.png`
            }
            alt={`Logotip podjetja ${props.company.basic.longName}`}
            className="logo-image"
            height={"100%"}
            width={"90%"}
            datatype="image"
            name={`${imageName}-logo`}
            id="input-img-2"
            onchange={handleInputChange}
            nextbtn="Shrani sliko"
            nextimg={true}
          />
        </div>
      </div>
      <div className="information-page">
        <div className="basic-data-box">
          <div className="title-box">Ime podjetja</div>
          <div className="data-box-row">
            <InputBox
              label="Kratko ime:"
              id="input-bar-1"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container-multiple"
              name="shortName"
              datatype="basic"
              type="text"
              onchange={handleInputChange}
              defaultvalue={props.company.basic.shortName}
            />
            <InputBox
              label="Polno ime:"
              id="input-bar-2"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container-multiple"
              name="longName"
              datatype="basic"
              type="text"
              onchange={handleInputChange}
              defaultvalue={props.company.basic.longName}
            />
          </div>
        </div>
        <div className="main-data-box">
          <div className="legal-data-box">
            <div className="title-box">Pravni podatki</div>
            <div className="data-box-col">
              <InputBox
                label="Davčna številka:"
                id="input-bar-3"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-left"
                name="taxNumber"
                datatype="legal"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.legal.taxNumber}
              />
              <InputBox
                label="Matična številka:"
                id="input-bar-4"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-left"
                name="registrationNumber"
                datatype="legal"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.legal.registrationNumber}
              />
              <InputBox
                label="Številka računa (TRR):"
                id="input-bar-5"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-left"
                name="accountNumber"
                datatype="legal"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.legal.accountNumber}
              />
              <span>
                <DropDownMenu
                  label="Pravna oblika:"
                  placeholder={legalFromParser(props.company.legal.legalForm)}
                  id="input"
                  name="legalForm"
                  datatype="legal"
                  onchange={handleInputChange}
                  data={[
                    { value: 1, text: "Samostojni podjetnik (s.p.)" },
                    {
                      value: 2,
                      text: "Družba z omejeno odgovornostjo (d.o.o.)",
                    },
                    {
                      value: 3,
                      text: "Družba z neomejeno odgovornostjo (d.n.o.)",
                    },
                    { value: 4, text: "Komanditna družba (k.d.)" },
                    { value: 5, text: "Delniška družba (d.d.)" },
                    { value: 6, text: "Komanditna delniška družba (k.d.d.)" },
                    { value: 7, text: "Podružnica" },
                    { value: 8, text: "Gospodarsko interesno združenje" },
                    { value: 9, text: "Socialno podjetništvo" },
                    { value: 10, text: "Invalidsko podjetje" },
                    { value: 11, text: "Zavod" },
                    { value: 12, text: "Društvo" },
                    { value: 13, text: "Ustanova" },
                    { value: 14, text: "Kmetijsko gospodarstvo" },
                    { value: 15, text: "Mladinska organizacija" },
                    { value: 16, text: "Status kmeta" },
                    { value: 17, text: "Zadruga" },
                    { value: 18, text: "Status samostojnega novinarja" },
                    { value: 19, text: "Status samozaposlenega v kulturi" },
                    { value: 20, text: "Status zasebnega raziskovalca" },
                    { value: 21, text: "Kratkotrajno delo" },
                    { value: 22, text: "Osebno dopolnilno delo" },
                    {
                      value: 23,
                      text: "Začasno ali občasno delo upokojencev",
                    },
                    { value: 24, text: "Sobodajalstvo" },
                  ]}
                />
              </span>
              <span>
                <DropDownMenu
                  label="Kategorija:"
                  placeholder={categoryParser(props.company.legal.category)}
                  id="input-2"
                  name="category"
                  datatype="legal"
                  onchange={handleInputChange}
                  data={[
                    { value: 1, text: "Energetika" },
                    { value: 2, text: "Gradbeništvo" },
                    { value: 3, text: "Nepremičnine" },
                    { value: 4, text: "Proizvodnja" },
                    { value: 5, text: "Izobraževanje" },
                    { value: 6, text: "Kmetijstvo, gozdarstvo in ribištvo" },
                    { value: 7, text: "Kultura" },
                    { value: 8, text: "Transport" },
                    { value: 9, text: "Turizem in gostinstvo" },
                    { value: 10, text: "Trgovina in storitve" },
                    { value: 11, text: "Finančne storitve" },
                    {
                      value: 12,
                      text: "Tehnologija in informacijske storitve",
                    },
                    { value: 13, text: "Industrija" },
                    { value: 14, text: "Poslovne storitve" },
                  ]}
                />
              </span>
              <span>Datum pridružitve: {props.company.basic.joinDate}</span>
            </div>
          </div>
          <div className="address-data-box">
            <div className="title-box">Naslovni podatki</div>
            <div className="data-box-col">
              <InputBox
                label="Naslov:"
                id="input-bar-6"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-left"
                name="street"
                datatype="address"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.address.street}
              />
              <InputBox
                label="Pošta:"
                id="input-bar-7"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-left"
                name="post"
                datatype="address"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.address.post}
              />
              <InputBox
                label="Občina:"
                id="input-bar-8"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-left"
                name="municipality"
                datatype="address"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.address.municipality}
              />
              <span>
                <DropDownMenu
                  label="Regija:"
                  placeholder={regionParser(props.company.address.region)}
                  id="input-3"
                  name="region"
                  datatype="address"
                  onchange={handleInputChange}
                  data={[
                    { value: 1, text: "Gorenjska regija" },
                    { value: 2, text: "Goriška regija" },
                    { value: 3, text: "Jugovzhodna slovenija" },
                    { value: 4, text: "Koroška regija" },
                    { value: 5, text: "Obalno-kraška regija" },
                    { value: 6, text: "Osrednjeslovenska regija" },
                    { value: 7, text: "Podravska regija" },
                    { value: 8, text: "Pomurska regija" },
                    { value: 9, text: "Posavska regija" },
                    { value: 10, text: "Primorsko-notranjska regija" },
                    { value: 11, text: "Savinjska regija" },
                    { value: 12, text: "Zasavska regija" },
                  ]}
                />
              </span>
              <span>Država: {props.company.address.country}</span>
            </div>
          </div>
        </div>
        <div className="contact-data-box">
          <div className="representative-box">
            <div className="title-box">Predstavnik podjetja</div>
            <div className="data-box-row">
              <InputBox
                label="Ime predstavnika:"
                id="input-bar-12"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="representativeName"
                datatype="representative"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.representative.representativeName}
              />
              <InputBox
                label="Priimek predstavnika:"
                id="input-bar-13"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="representativeLastName"
                datatype="representative"
                type="text"
                onchange={handleInputChange}
                defaultvalue={
                  props.company.representative.representativeLastName
                }
              />
              <InputBox
                label="E-naslov predstavnika:"
                id="input-bar-14"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="representativeEmail"
                datatype="representative"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.representative.representativeEmail}
              />
            </div>
          </div>
          <div className="contact-box">
            <div className="title-box">Kontaktni podatki</div>
            <div className="data-box-row">
              <InputBox
                label="Telefonska številka:"
                id="input-bar-15"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="phoneNumber"
                datatype="contact"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.contact.phoneNumber}
              />
              <InputBox
                label="E-naslov:"
                id="input-bar-16"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container-multiple"
                name="email"
                datatype="contact"
                type="text"
                onchange={handleInputChange}
                defaultvalue={props.company.contact.email}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="update-company-offer-page">
        <ImageInput
          src={
            imagePreviewObj.productsImg
              ? imagePreviewObj.productsImg
              : `${process.env.REACT_APP_COMPANY_IMAGES_URL}${imageName}-productsImg.png`
          }
          alt={`Slika, ki opisuje ponudbo podjetja ${props.company.basic.longName}`}
          className="productsImg-image"
          height={"100%"}
          width={"80%"}
          datatype="image"
          name={`${imageName}-productsImg`}
          id="input-img-3"
          onchange={handleInputChange}
          nextbtn="Shrani sliko"
          nextimg={true}
        />
      </div>
      <div className="update-company-button-box">
        <button
          className="button-1"
          type="button"
          onClick={() => props.onsave(company, formData)}
        >
          Shrani
        </button>
        <button
          className="button-2"
          type="button"
          onClick={props.handleupdatecompanywindow}
        >
          Zapri
        </button>
      </div>
    </div>
  );
};

export default UpdateCompanyWindow;
