import axios from "axios";

const insertNewCompany = (company) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_INSERT_NEW_COMPANY,
        { company },
        { withCredentials: true }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default insertNewCompany;
