import axios from "axios";

const insertCompanyImages = (fd) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_INSERT_COMPANY_IMAGES, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default insertCompanyImages;
