import React, { useEffect } from "react";

import "../Assets/Styles/Components/Loader.css";

const Loader = () => {
  
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return (
    <div className="Loader-page">
      <span className="Loader"></span>
    </div>
  );
};

export default Loader;
