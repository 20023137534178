import React, { useState } from "react";
import CheckBox from "../Components/CheckBox";
import "../Assets/Styles/Components/FilterBoxes.css"

const RegionFilter = (props) => {
  return (
    <div className="filter-box">
      <CheckBox
        label="Gorenjska regija"
        id={`checkbox-${props.index}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="1"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Goriška regija"
        id={`checkbox-${props.index + 1}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="2"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Jugovzhodna slovenija"
        id={`checkbox-${props.index + 2}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="3"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Koroška regija"
        id={`checkbox-${props.index + 3}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="4"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Obalno-kraška regija"
        id={`checkbox-${props.index + 4}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="5"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Osrednje-slovenska regija"
        id={`checkbox-${props.index + 5}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="6"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Podravska regija"
        id={`checkbox-${props.index + 6}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="7"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Pomurska regija"
        id={`checkbox-${props.index + 7}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="8"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Posavska regija"
        id={`checkbox-${props.index + 8}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="9"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Primorsko-notranjska regija"
        id={`checkbox-${props.index + 9}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="10"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Savinjska regija"
        id={`checkbox-${props.index + 10}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="11"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
      <CheckBox
        label="Zasavska regija"
        id={`checkbox-${props.index + 11}`}
        labelclass="label"
        datatype="filters"
        name="region"
        value="12"
        onchange={(e) =>
          props.handlefilters(e.target.value, e.target.name, e.target.checked)
        }
      />
    </div>
  );
};

export default RegionFilter;

// label =
// id =
// labelclass =
// datatype =
// name =
// value =
// onchange =
// ischecked =
