import React, { useEffect, useState } from "react";

import "../Assets/Styles/Pages/Company.css";
import legalFromParser from "../Components/utils/LegalFormParser";
import categoryParser from "../Components/utils/CategoryParser";
import regionParser from "../Components/utils/RegionParser";
import { DIACRITICS, SPECIAL_CHARS } from "../Constants/RegexPatterns";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import ErrorWindow from "../Components/ErrorWindow";
import getOfferData from "../Services/getOfferData";
import Offer from "./Offer";

const Company = (props) => {
  const [imageName, setImageName] = useState(undefined);

  // #region Set image name
  useEffect(() => {
    setImageName(
      `${props.company?.basic.ID}-${props.company?.legal.taxNumber}`
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [props.company?.address, props.company?.legal]);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [errorTitle, setErrorTitle] = useState(undefined);
  const [isOpenOfferPage, setIsOpenOfferPage] = useState(false);

  const [offer, setOffer] = useState({});

  // #region Handle Offer page
  const handleOfferPage = async (offerId) => {
    if (!isOpenOfferPage) {
      try {
        const response = await getOfferData(offerId);

        if (response.status === 200) {
          setOffer(response.data.offer);
          setIsOpenOfferPage(!isOpenOfferPage);
        } else {
          setError(true);
          setErrorText(
            "Pri pošiljanju podatkov je prišlo do napake poskusite kasneje ali kontaktirajte našo podporno ekipo."
          );
          setErrorTitle("NAPAKA");
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setErrorText(error?.data?.message);
        setErrorTitle(ErrorCodesParser(error?.data?.errorCode));
      }
    } else {
      setIsOpenOfferPage(!isOpenOfferPage);
    }
  };

  // #region HANDLE ERROR WINDOW
  const handleErrorWindow = () => {
    setError(!error);
  };

  // #region HANDLE DEFAULT IMAGE
  const handleDefaultImage = (e) => {
    e.target.src = "/addImageIcon.svg";
  };
  // #region JSX
  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {isOpenOfferPage && (
        <Offer companyid={props.company.basic.ID} offer={offer} />
      )}

      {!isOpenOfferPage && (
        <div className="company-page">
          <div className="intro-page">
            <img
              src={`${process.env.REACT_APP_COMPANY_IMAGES_URL}${imageName}-intro.png`}
              alt={`Uvodna slika podjetja ${props.company?.basic.longName}`}
              className="intro-image"
              onError={(e) => handleDefaultImage(e)}
            />
          </div>
          <div className="description-page">
            <div className="description-text">
              {props.company?.description.text}
            </div>
            <img
              src={`${process.env.REACT_APP_COMPANY_IMAGES_URL}${imageName}-logo.png`}
              alt={`Logotip podjetja ${props.company?.basic.longName}`}
              className="logo-image"
              onError={(e) => handleDefaultImage(e)}
            />
          </div>
          <div className="information-page">
            <div className="basic-data-box">
              <div className="title-box">Ime podjetja</div>
              <div className="data-box-row">
                <span>Kratko ime: {props.company?.basic.shortName}</span>
                <span>Polno ime: {props.company?.basic.longName}</span>
              </div>
            </div>
            <div className="main-data-box">
              <div className="legal-data-box">
                <div className="title-box">Pravni podatki</div>
                <div className="data-box-col">
                  <span>
                    Davčna številka:{" "}
                    {props.company?.legal.taxPayer === 1 ? "SI" : ""}
                    {props.company?.legal.taxNumber}
                  </span>
                  <span>
                    Matična številka: {props.company?.legal.registrationNumber}
                  </span>
                  <span>
                    Številka računa (TRR): {props.company?.legal.accountNumber}
                  </span>
                  <span>
                    Pravna oblika:
                    {legalFromParser(props.company?.legal.legalForm)}
                  </span>
                  <span>
                    Kategorija: {categoryParser(props.company?.legal.category)}
                  </span>
                  <span>
                    Datum pridružitve: {props.company?.basic.joinDate}
                  </span>
                </div>
              </div>
              <div className="address-data-box">
                <div className="title-box">Naslovni podatki</div>
                <div className="data-box-col">
                  <span>Naslov: {props.company?.address.street}</span>
                  <span>Pošta: {props.company?.address.post}</span>
                  <span>Občina: {props.company?.address.municipality}</span>
                  <span>
                    Regija: {regionParser(props.company?.address.region)}
                  </span>
                  <span>Država: {props.company?.address.country}</span>
                </div>
              </div>
            </div>
            <div className="contact-data-box">
              <div className="representative-box">
                <div className="title-box">Predstavnik podjetja</div>
                <div className="data-box-row">
                  <span>
                    Ime: {props.company?.representative.representativeName}
                  </span>
                  <span>
                    Priimek:
                    {props.company?.representative.representativeLastName}
                  </span>
                  <span>
                    E-naslov:{" "}
                    {props.company?.representative.representativeEmail}
                  </span>
                </div>
              </div>
              <div className="contact-box">
                <div className="title-box">Kontaktni podatki</div>
                <div className="data-box-row">
                  <span>
                    Telefonska številka: {props.company?.contact.phoneNumber}
                  </span>
                  <span>E-naslov: {props.company?.contact.email}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="offer-page">
            <div className="offer-box">
              <OfferDisplay
                productsimgurl={`${process.env.REACT_APP_COMPANY_IMAGES_URL}${imageName}-productsImg.png`}
                offer={props.company?.offer}
                companyname={props.company?.basic.longName}
                companyid={props.company?.basic.ID}
                handleofferpage={handleOfferPage}
                handledefaultimage={handleDefaultImage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// #region END JSX

export default Company;

// #region Display offer
const OfferDisplay = (props) => {
  const [offerArr, setOfferArr] = useState([]);
  const [secondaryArr, setSecondaryArr] = useState([]);
  const [maxLength, setMaxLength] = useState(0);

  useEffect(() => {
    const tempMaxLength = Math.max(
      props.offer?.service.length,
      props.offer?.product.length
    );

    setMaxLength(props.offer?.service.length + props.offer?.product.length);

    const newOfferArr = [];

    for (let i = 0; i < tempMaxLength; i++) {
      if (props.offer?.service[i]) newOfferArr.push(props.offer?.service[i]);
      if (props.offer?.product[i]) newOfferArr.push(props.offer?.product[i]);
    }

    if (newOfferArr.length > 6) {
      const tempArr1 = newOfferArr.slice(0, 6);
      const tempArr2 = newOfferArr.slice(6, 6 + newOfferArr.length);

      setOfferArr(tempArr1);
      setSecondaryArr(tempArr2);

      return;
    }

    setOfferArr(newOfferArr);
  }, [props.offer]);

  if (maxLength === 0) {
    return (
      <div className="image-box-s-offers">
        <img
          src={props.productsimgurl}
          alt={`Slika ki opisuje prazni offer ponudbo podjetja ${props.companyname}`}
          className="productsImg-0"
          onError={(e) => props.handledefaultimage(e)}
        />
      </div>
    );
  } else if (maxLength > 0 && maxLength < 7) {
    return (
      <div className="offer-page-m-offers">
        <div className="image-box-m-offers">
          <img
            src={props.productsimgurl}
            alt={`Slika ki opisuje offer ponudbo podjetja ${props.companyname}`}
            className="productsImg-0"
            onError={(e) => props.handledefaultimage(e)}
          />
        </div>
        <div className="offers-box-m-offers">
          {offerArr.map((value, key) => (
            <span key={key} onClick={() => props.handleofferpage(value.ID)}>
              <img
                src={`${process.env.REACT_APP_OFFER_IMAGES_URL}${
                  props.companyid
                }-${value.name
                  .toLowerCase()
                  .replace(DIACRITICS, "")
                  .replace(SPECIAL_CHARS, "")}-${value.offerType}.png`}
                alt={`Na sliki je: ${value.name}`}
                onError={(e) => props.handledefaultimage(e)}
              />
            </span>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="offer-page-l-offers">
        <div className="offer-page-m-offers">
          <div className="image-box-m-offers">
            <img
              src={props.productsimgurl}
              alt={`Slika ki opisuje offer ponudbo podjetja ${props.companyname}`}
              className="productsImg-0"
            />
          </div>
          <div className="offers-box-m-offers">
            {offerArr.map((value, key) => (
              <span key={key} onClick={() => props.handleofferpage(value.ID)}>
                <img
                  src={`${process.env.REACT_APP_OFFER_IMAGES_URL}${
                    props.companyid
                  }-${value.name
                    .toLowerCase()
                    .replace(DIACRITICS, "")
                    .replace(SPECIAL_CHARS, "")}-${value.offerType}.png`}
                  alt={`Na sliki je: ${value.name}`}
                />
              </span>
            ))}
          </div>
        </div>
        <div className="offers-box-l-offers">
          {secondaryArr.map((value, key) => (
            <span key={key} onClick={() => props.handleofferpage(value.ID)}>
              <img
                src={`${process.env.REACT_APP_OFFER_IMAGES_URL}${
                  props.companyid
                }-${value.name
                  .toLowerCase()
                  .replace(DIACRITICS, "")
                  .replace(SPECIAL_CHARS, "")}-${value.offerType}.png`}
                alt={`Na sliki je: ${value.name}`}
              />
            </span>
          ))}
        </div>
      </div>
    );
  }
};
