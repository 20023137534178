import axios from "axios";

const getDataOneParam = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: { params } }, { withCredentials: true })
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default getDataOneParam;
