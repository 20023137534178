import axios from "axios";
const changeUsersData = (user) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        process.env.REACT_APP_UPDATE_USERS_DATA,
        { user },
        { withCredentials: true }
      )
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default changeUsersData;
