import axios from "axios";

const addNewUser = (user) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_ADD_NEW_USER,
        { user },
        { withCredentials: true }
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export default addNewUser;
