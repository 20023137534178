import React, { useEffect, useState } from "react";

// import "../Assets/Styles/Components/InputBox.css";
import "../Assets/Styles/Components/InputDate.css";
const InputDate = (props) => {
  return (
    <div className={props.inputcontainer}>
      <div className="input-box">
        <label htmlFor={props.id} className={props.labelclass}>
          {props.label}
        </label>
        <div className="input-wrapper">
          <input
            type={props.type}
            id={props.id}
            className={props.class}
            name={props.name}
            onChange={(e) => props.onchange(e, props.datatype)}
            defaultValue={props.defaultvalue}
            autoComplete="on"
            min={new Date().toISOString().split("T")[0]}
            max={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
          />
        </div>
      </div>
    </div>
  );
};

export default InputDate;
