export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const PHONE_NUMBER_REGEX =
  /^((\+\d{2,3})?|(\(\d{2}\))?|\d{3}?|\d{2})?[ -\d][ -]?\d{2,}[ -]?\d{2,}[ -]?\d{2,}$/;
export const ACCOUNT_NUMBER_REGEX =
  /\w{2}\d{2}[ -]?\d{4}[ -]?\d{4}[ -]?\d{4}[ -]?\d{3}/;
export const STREET_REGEX = /^[A-Za-zčšžČŠŽ\s]+\d+[a-zA-ZčšžČŠŽ]?/;
export const POST_REGEX = /^\d{4}[\s]?[a-zA-ZčšžČŠŽ]+/;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,}$/;
export const SPECIAL_CHARS = /[\s~`!@#$%^&*(){}[\];:"'<,.>?/\\|_+=-]/g;
export const DIACRITICS =
  /[čćđšžČĆĐŠŽáàâäãåéèêëíìîïóòôöõúùûüýÿÁÀÂÄÃÅÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝ]/g;
export const DATE_REGEX =
  /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;
export const NUMBER_REGEX = /^\d+$/;
export const PRICE_REGEX = /^\d+(\.{0,1}\d*\,{0,1}\d{0,2})?\€/;
export const HOW_LONG_REGEX = /^\d+\s\w+$/;
