import React, { useEffect, useState } from "react";

import getCroppedImg from "./utils/ImageCropDialog";
import ImageCropper from "./ImageCropper";

import "../Assets/Styles/Components/ImageInput.css";

const ImageInput = (props) => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState();
  // #region LOAD IMAGE 
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }

    const objUrl = URL.createObjectURL(image);
    setPreview(objUrl);

    return () => URL.revokeObjectURL(objUrl);
  }, [image]);

  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(preview, croppedAreaPixels);
    setCroppedImage(croppedImageUrl);
  };
  // #region SAVE IMAGE
  const saveImage = async (e) => {
    fetch(e.target.value)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], props.name, { type: blob.type });
        props.onchange(file, props.datatype);
      });
    setCroppedImage(null);
    setImage(null);
  };

  // #region HANDLE DEFAULT IMAGE
  const handleDefaultImage = (e) => {
    e.target.src = "/addImageIcon.svg";
  };

  // #region JSX
  return (
    <>
      <h1 className="title">{props.title}</h1>
      {!image && (
        <label className="input-label" htmlFor={props.id}>
          <img
            src={props.src}
            alt={props.alt}
            className={props.classname}
            height={props.height}
            width={props.width}
            onError={(e) => handleDefaultImage(e)}
          />
        </label>
      )}
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        id={props.id}
        onChange={(e) => {
          setImage(e.target.files[0]);
        }}
        name="file"
        hidden
      />
      {image && !croppedImage && (
        <div className="add-service-image-page">
          <div className="image-cropper-container">
            <div
              className="cropper-box"
              style={{ height: props.height, width: props.width }}
            >  
              <ImageCropper
                image={preview}
                crop={crop}
                aspect={4 / 3}
                zoom={zoom}
                onzoomchange={onZoomChange}
                oncropchange={onCropChange}
                oncropcomplete={onCropComplete}
              />
            </div>
            <button className="button-1" type="button" onClick={onCrop}>
              Obreži
            </button>
          </div>
        </div>
      )}
      {croppedImage && (
        <>
          <img
            src={croppedImage}
            alt="Predogled slike"
            className="preview-image"
            style={{ height: props.height, width: props.width }}
          />
          <div className="button-box">
            {props.backbtn && props.backimg && (
              <button
                className="button-1"
                onClick={() => {
                  props.backimg();
                  setCroppedImage(null);
                }}
              >
                {props.backbtn}
              </button>
            )}
            <button
              className="button-1"
              onClick={() => {
                setCroppedImage(null);
                setImage(null);
              }}
            >
              Zamenjaj sliko
            </button>
            {props.nextbtn && props.nextimg && (
              <button
                className="button-1"
                name={props.name}
                value={croppedImage}
                onClick={(e) => {
                  saveImage(e);
                  if (typeof props.nextimg === "function") {
                    props.nextimg();
                  }
                }}
              >
                {props.nextbtn}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ImageInput;
