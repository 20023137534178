import React from "react";

import "../Assets/Styles/Components/DropDownMenu.css";

const DropDownMenu = (props) => {
  return (
    <div className={props.dropdownbox}>
      <div className="custom-dropdown">
        <select
          onChange={(e) => props.onchange(e, props.datatype)}
          id={props.id}
          name={props.name}
          style={{ display: "block" }}
        >
          <option value="0">{props.placeholder}</option>
          {props.data.map((item, key) => (
            <option key={key} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropDownMenu;
