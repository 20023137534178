import axios from "axios";

const postDataTwoParams = (url, param1, param2) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, { param1, param2 }, { withCredentials: true })
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default postDataTwoParams;
