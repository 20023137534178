import React from "react";

import "../Assets/Styles/Components/CheckBox.css";

const CheckBox = (props) => {
  return (
      <div className="checkbox-wrapper">
        <label className={props.labelclass} htmlFor={props.id}>
          {props.label}
        </label>
        <input
          type="checkbox"
          id={props.id}
          name={props.name}
          value={props.value}
          checked={props.ischecked}
          onChange={(e) => props.onchange(e, props.datatype)}
        />
      </div>
  );
};

export default CheckBox;
