import React, { useState } from "react";
import InputBox from "../Components/InputBox";
import CheckBox from "../Components/CheckBox";
import { Link, useNavigate } from "react-router-dom";
import validateUserData from "../Components/utils/ValidateUserData";
import ErrorWindow from "../Components/ErrorWindow";
import Loader from "../Components/Loader";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import addNewUser from "../Services/addNewUser";
import VerifyEmail from "../Components/VerifyEmail";

import "../Assets/Styles/Pages/UserRegister.css";

const UserRegister = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    basic: {},
    flags: {
      privacyPolicy: false,
      getOffers: false,
    },
  });
  const [isCheckedPrivacyPolicy, setIsCheckedPrivacyPolicy] = useState(false);
  const [isCheckedGetOffers, setIsCheckedGetOffers] = useState(false);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const changeOfferInput = (e, datatype) => {
    if (datatype === "flags") {
      setUser({
        ...user,
        [datatype]: { ...user[datatype], [e.target.name]: e.target.checked },
      });
      if (e.target.name === "privacyPolicy")
        setIsCheckedPrivacyPolicy(!isCheckedPrivacyPolicy);
      if (e.target.name === "getOffers")
        setIsCheckedGetOffers(!isCheckedGetOffers);

      return;
    }

    setUser({
      ...user,
      [datatype]: { ...user[datatype], [e.target.name]: e.target.value },
    });
  };

  const [openVerifyEmailWindow, setOpenVerifyEmailWindow] = useState(false);

  const registerUser = async () => {
    setIsLoading(true);
    //TODO: Validate data
    const { errorFound, errorTitle, errorText } = validateUserData(user);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);
    if (!errorFound) {
      try {
        const response = await addNewUser(user);

        if (response.status === 200) {
          setIsLoading(false);
          if (response.data?.code === 200) {
            setOpenVerifyEmailWindow(true);
          }
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleErrorWindow = () => {
    setError(!error);
  };

  const handleVerifyEmailWindow = () => {
    setOpenVerifyEmailWindow(!openVerifyEmailWindow);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const submitForm = (e) => {
    if (e.key === "Enter") {
      registerUser();
    }
  };


  return (
    <div className="register-page" onKeyDown={submitForm}>
      {!error && !isLoading && openVerifyEmailWindow && (
        <VerifyEmail
          email={user.basic.email}
          handleverifyemailwindow={handleVerifyEmailWindow}
          changeemail={true}
        />
      )}

      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && isLoading && <Loader />}
      <div className="register-box">
        <h1 className="title">REGISTRACIJA UPORABNIKA</h1>
        <div className="input-fields">
          <InputBox
            label="Ime: "
            id="input-bar-1"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-center"
            name="firstName"
            placeholder="Vnesi ime..."
            datatype="basic"
            type="text"
            onchange={changeOfferInput}
          />
          <InputBox
            label="Priimek: "
            id="input-bar-2"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-center"
            name="lastName"
            placeholder="Vnesi priimek..."
            datatype="basic"
            type="text"
            onchange={changeOfferInput}
          />
          <InputBox
            label="E-naslov: "
            id="input-bar-3"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-center"
            name="email"
            placeholder="Vnesi e-naslov..."
            datatype="basic"
            type="text"
            onchange={changeOfferInput}
          />
          <InputBox
            label="Geslo: "
            id="input-bar-4"
            class="input-password"
            labelclass="label"
            inputcontainer="input-container-center"
            name="password"
            placeholder="Vnesi geslo...."
            datatype="basic"
            type={showPassword ? "text" : "password"}
            onchange={changeOfferInput}
            showpassword={() => setShowPassword(!showPassword)}
          />
          <InputBox
            label="Potrdi geslo: "
            id="input-bar-5"
            class="input-password"
            labelclass="label"
            inputcontainer="input-container-center"
            name="confirmPassword"
            placeholder="Potrdi geslo..."
            datatype="basic"
            type={showConfirmPassword ? "text" : "password"}
            onchange={changeOfferInput}
            showpassword={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        </div>
        <div className="checkbox-box">
          <CheckBox
            label="Politika zasebnosti"
            id="checkbox-1"
            labelclass="label"
            datatype="flags"
            name="privacyPolicy"
            value="true"
            onchange={changeOfferInput}
            ischecked={isCheckedPrivacyPolicy}
          />
          <CheckBox
            label="Dobi ponudbe"
            id="checkbox-2"
            labelclass="label"
            datatype="flags"
            name="getOffers"
            value="true"
            onchange={changeOfferInput}
            ischecked={isCheckedGetOffers}
          />
        </div>
        <div className="fine-print">
          <Link className="link" to={"/politikaZasebnosti"}>
            Več o politiki zasebnosti: [odkrij.si]politika_zasebnosti
          </Link>
          <Link className="link" to={"/"}>
            Več o pridobivanju ponudbe: [odkrij.si]pridobivanje_ponudbe
          </Link>
        </div>
        <div className="button-box">
          <button className="button-1" onClick={() => navigate(-1)}>
            Nazaj
          </button>
          <button className="button-1" onClick={registerUser}>
            Registracija
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserRegister;
