import axios from "axios";

const verifyEmail = (email, code, password) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_VERIFY_EMAIL, {
        params: {
          code: code,
          email: email,
          password: password,
        },
        withCredentials: true,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export default verifyEmail;
