import axios from "axios";

const searchQuery = (searchQuery) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_SEARCH_QUERY_URL}${searchQuery}`, {
        withCredentials: true,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export default searchQuery;
