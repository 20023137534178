import { badWords } from "../../Constants/badWords";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../Constants/RegexPatterns";

const ValidateUserData = (user) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";
  let password;
  for (const attribute in user) {
    for (const [key, value] of Object.entries(user[attribute])) {
      switch (attribute) {
        case "basic":
          switch (key) {
            case "firstName":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "IME UPORABNIKA";
              }
              break;
            case "lastName":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = "PRIIMEK UPORABNIKA";
              }
              break;
            case "email":
              if (!EMAIL_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: janez.novak@odkrij.si";
                errorTitle = "E-NASLOV UPORABNIKA";
              }
              break;
            case "password":
              if (!PASSWORD_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Geslo je v nepravilnem formatu vsebovati mora 1 veliko črko vsaj 8 znakov, vsaj 1 poseben znak in vsaj 1 številko";
                errorTitle = "GESLO UPORABNIKA";
              }
              password = value;
              break;
            case "confirmPassword":
              if (password !== value) {
                errorFound = true;
                errorText =
                  "Gesli se ne ujemata preverite podatke in poskusite znova.";
                errorTitle = "POTDRI GESLO";
              }
              break;
            default:
              errorFound = true;
              errorText = "Napačno polje v razdelku osnovno!";
              errorTitle = "NAPAČEN VNOS";
              break;
          }
          break;
        case "flags":
          switch (key) {
            case "privacyPolicy":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "POLITIKA ZASEBNOSTI";
              }
              break;
            case "getOffers":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "PONUDBE";
              }
              break;
            default:
              //console.log(key + " " + value);
              errorFound = true;
              errorText = "Napačno polje v razdelku zastavice!";
              errorTitle = "NAPAČEN VNOS";
              break;
          }
          break;
        default:
          errorFound = true;
          errorText = "Napačno polje v obrazcu!";
          errorTitle = "NAPAČEN VNOS!";
          break;
      }
    }
  }

  return { errorFound, errorText, errorTitle };
};

export default ValidateUserData;

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase)) {
      return true;
    }
  }

  return false;
};

const isBoolean = (value) => {
  return value === true || value === false;
};
