import { badWords } from "../../Constants/badWords";

const validateSearchQuery = (value) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";
  if (containsBadWord(value)) {
    errorFound = true;
    errorText = "Vaš poizvedovalni niz vsebuje besede, ki so neprimerne ali ne dovoljne, prosimo odstranite te besede in poskusite ponovno."
    errorTitle = "NEPRIMERNE BESEDE"
  }

  return { errorFound, errorText, errorTitle };
};

export default validateSearchQuery;

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase())) {
      return true;
    }
  }
};
