import axios from "axios";

const logoutUser = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_LOGOUT_USER, { withCredentials: true })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export default logoutUser;
