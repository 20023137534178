import axios from "axios";

const forgottenPassword = (newPassword, email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_FORGOTTEN_PASSWORD,
        { newPassword, email },
        { withCredentials: true }
      )
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default forgottenPassword;
