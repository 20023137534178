import axios from "axios";

const postData = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, { params }, { withCredentials: true })
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default postData;
