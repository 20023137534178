import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddService from "../Components/AddService";
import AddProduct from "../Components/AddProduct";
import validateOfferData from "../Components/utils/ValidateOfferData";
import insertNewOffer from "../Services/insertNewOffer";
import insertCompanyImages from "../Services/insertCompanyImages";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import { DIACRITICS, SPECIAL_CHARS } from "../Constants/RegexPatterns";
import ErrorWindow from "../Components/ErrorWindow";
import Header from "../Components/Header"
import Footer from "../Components/Footer"

const AddNewOffer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { companyId, offerType } = location.state;

  const [charCountOffer, setCharCountOffer] = useState(0);
  const [charCountReturn, setCharCountReturn] = useState(0);
  const [isCheckedWarranty, setIsCheckedWarranty] = useState(false);
  const [isCheckedReturn, setIsCheckedReturn] = useState(false);
  const [isCheckedOrderItem, setIsCheckedOrderItem] = useState(false);
  const [imageNameOffer, setImageNameOffer] = useState(undefined);
  const [companyOffer, setCompanyOffer] = useState({});

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const formData = useRef(new FormData());

  const handleInputChange = (e, datatype) => {
    if (datatype === "image") {
      const images = formData.current.get("images");

      if (images) {
        formData.current.set("images", e);
      } else {
        formData.current.append("images", e);
      }
    } else if (datatype === "flags") {
      setCompanyOffer({
        ...companyOffer,
        [datatype]: {
          ...companyOffer[datatype],
          [e.target.name]: e.target.checked,
        },
      });
      if (e.target.name === "return") {
        setIsCheckedReturn(!isCheckedReturn);
      }

      if (e.target.name === "warranty") {
        setIsCheckedWarranty(!isCheckedWarranty);
      }

      if (e.target.name === "orderItem") {
        setIsCheckedOrderItem(!isCheckedOrderItem);
      }
    } else {
      if (e.target.name === "name") {
        const sanitizedName = e.target.value
          .toLowerCase()
          .replace(DIACRITICS, "")
          .replace(SPECIAL_CHARS, "");
        setImageNameOffer(`${companyId}-${sanitizedName}`);
      }

      setCompanyOffer({
        ...companyOffer,
        [datatype]: {
          ...companyOffer[datatype],
          [e.target.name]: e.target.value,
        },
      });
      if (e.target.name === "text") {
        setCharCountOffer(e.target.value.length);
      }
      if (e.target.name === "returnConditions") {
        setCharCountReturn(e.target.value.length);
      }
    }
  };

  const addOffer = async (offertype) => {
    const companyOfferArr = {
      service: [],
      product: [],
    };

    if (offerType.toLowerCase() === "service") {
      companyOfferArr.service.push(companyOffer);
    } else {
      companyOfferArr.product.push(companyOffer);
    }

    const { errorFound, errorText, errorTitle } =
      validateOfferData(companyOfferArr);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);

    if (
      formData.current.getAll("images").length !==
      companyOfferArr.service.length + companyOfferArr.product.length
    ) {
      setError(true);
      setErrorText(
        "V obrazcu ste pozabili vnesti slike ponudbe preverite podatke ali kontaktirajte našo podporno ekipo."
      );
      setErrorTitle("MANJKAJO SLIKE PODJETJA");
      return;
    }

    if (!errorFound && companyId) {
      try {
        const response = await insertNewOffer(companyOfferArr, companyId);
        if (response.status === 200) {
          if (response?.data?.affectedRows > 0) {
            await insertCompanyImages(formData.current);
            navigate(-1);
          }

          if (response?.data?.duplicateArr.length > 0) {
            let text = "";
            for (const item of response.data.duplicateArr) {
              text += item + ", ";
            }
            const displayText = text.slice(0, -2);

            setError(true);
            setErrorText(`Ponudbe: (${displayText}) že obstajajo.`);
            setErrorTitle("DUPLIKAT PONUDBE");
          }
        }
      } catch (err) {
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    }

    setIsCheckedReturn(false);
    setIsCheckedWarranty(false);
  };

  const handleErrorWindow = () => {
    setError(!error);
  };

  return (
    <>
    <Header />
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}
      {offerType.toLowerCase() === "service" ? (
        <AddService
          changeofferinput={handleInputChange}
          charcount={charCountOffer}
          imagename={imageNameOffer}
          offertype="product"
          addoffer={addOffer}
          ischeckedorderitem={isCheckedOrderItem}
          goback={() => {
            navigate(-1);
          }}
        />
      ) : (
        <AddProduct
          changeofferinput={handleInputChange}
          charcount={charCountOffer}
          charcountreturn={charCountReturn}
          imagename={imageNameOffer}
          offertype="service"
          addoffer={addOffer}
          ischeckedwarranty={isCheckedWarranty}
          ischeckedreturn={isCheckedReturn}
          ischeckedorderitem={isCheckedOrderItem}
          return={isCheckedReturn}
          warranty={isCheckedWarranty}
          orderitem={isCheckedOrderItem}
          goback={() => {
            navigate(-1);
          }}
        />
      )}
      <Footer />
    </>
  );
};

export default AddNewOffer;
