import React from 'react'
import "../Assets/Styles/Components/Footer.css"
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    //TODO: Dodaj vse linke
    <div className='footer-box'>
      <span className='footer-item'><Link to={"/prijavaUporabnika"} className='footer-link'>Prijava</Link></span>
      <span className='footer-item'><Link to={"/prijavaPodjetja"} className='footer-link'>Prijava podjetja</Link></span>
      <span className='footer-item'>&copy; odkrij.si 2024</span>
      <span className='footer-item'><Link to={"/politikaZasebnosti"} className='footer-link'>Politika zasebnosti</Link></span>
      <span className='footer-item'><Link to={"/kontakt"} className='footer-link'>Kontakt</Link></span>
    </div>
  )
}

export default Footer