import React from 'react'

const ForgottenPassword = () => {
  return (
    <div>
       RESET PASSWORD
    </div>
  )
}

export default ForgottenPassword
