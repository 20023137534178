import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import "../Assets/Styles/Pages/OdkrijOffer.css";

const OdkrijOffer = () => {
  return (
    <>
      <Header />
      <div className="odkrij-offer-page">
        <div className="odkrij-offer-box">
          <div className="offer-box-item" id="special-offer-box">
            <span className="offer-promo-text">DO 2025 ZASTONJ Z KODO</span>
            <span className="offer-promo-code">ODKRIJ2024</span>
          </div>
          <div className="offer-box-item">
            <div id="special-price-box">
              <span className="offer-binding-price">9.99€</span>
              <span className="offer-binding-time" id="hexagon-box">
                <span className="hexagon-text">
                  24 <br></br>mesecev
                </span>
                <svg
                  className="hexagon"
                  id="hexagon-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 93.39 93.39"
                >
                  <polygon
                    className="cls-1"
                    points="65.83 .5 27.56 .5 .5 27.56 .5 65.83 27.56 92.89 65.83 92.89 92.89 65.83 92.89 27.56 65.83 .5"
                  />
                </svg>
              </span>
            </div>
            <div className="offer-box-item" id="old-price-box">
              <span className="offer-regular-price">12.49€</span>
            </div>
          </div>
          <div className="offer-box-item" id="offer-explanation-text-box">
            <span className="offer-explanation-text">
              Cena je veljavna ob sklenitvi vezave z več informacij nas
              kontaktirajte na
              <a className="offer-mail-text" href="mailto:info@odkrij.si">
                info@odkrij.si
              </a>
            </span>
          </div>
          <div className="offer-box-item" id="offer-type-box">
            <span className="offer-type-text">PODJETJE</span>
          </div>
        </div>
        <div className="odkrij-offer-box">
          <div className="offer-box-item" id="special-offer-box">
            <span className="offer-promo-text">DO 2025 ZASTONJ Z KODO</span>
            <span className="offer-promo-code">ODKRIJ2024</span>
          </div>
          <div className="offer-box-item">
            <div id="special-price-box">
              <span className="offer-binding-price">1.99€</span>
              <span className="offer-binding-time" id="hexagon-box">
                <span className="hexagon-text">
                  24 <br></br>mesecev
                </span>
                <svg
                  className="hexagon"
                  id="hexagon-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 93.39 93.39"
                >
                  <polygon
                    class="cls-1"
                    points="65.83 .5 27.56 .5 .5 27.56 .5 65.83 27.56 92.89 65.83 92.89 92.89 65.83 92.89 27.56 65.83 .5"
                  />
                </svg>
              </span>
            </div>
            <div className="offer-box-item" id="old-price-box">
              <span className="offer-regular-price">2.49€</span>
            </div>
          </div>
          <div className="offer-box-item" id="offer-explanation-text-box">
            <span className="offer-explanation-text">
              Cena je veljavna ob sklenitvi vezave z več informacij nas
              kontaktirajte na
              <a className="offer-mail-text" href="mailto:info@odkrij.si">
                info@odkrij.si
              </a>
            </span>
          </div>
          <div className="offer-box-item" id="offer-type-box">
            <span className="offer-type-text">PONUDBA</span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OdkrijOffer;
