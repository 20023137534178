import { badWords } from "../../Constants/badWords";
import { EMAIL_REGEX } from "../../Constants/RegexPatterns";

const ValidateChangeUserData = (userData) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";

  for (const attribute in userData) {
    for (const [key, value] of Object.entries(userData[attribute])) {
      if (key === "email") {
        if (!EMAIL_REGEX.test(value)) {
          errorFound = true;
          errorText =
            "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: janez.novak@odkrij.si";
          errorTitle = "E-NASLOV UPORABNIKA";
        }

        if (key === "firstName") {
          if (containsBadWord(value)) {
            errorFound = true;
            errorText =
              "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
            errorTitle = "IME UPORABNIKA";
          }
        }

        if (key === "lastName") {
          if (containsBadWord(value)) {
            errorFound = true;
            errorText =
              "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
            errorTitle = "PRIIMEK UPORABNIKA";
          }
        }
      }
    }
  }

  return { errorFound, errorText, errorTitle };
};

export default ValidateChangeUserData;

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase)) {
      return true;
    }
  }

  return false;
};
