export const badWords = [
  "drek",
  "smet",
  "rit",
  "gnida",
  "umazanec",
  "pokvarjenec",
  "bedak",
  "kurba",
  "kurva",
  "kurwa",
  "hudič",
  "peder",
  "lesba",
  "pederu",
  "pederčina",
  "pederćina",
  "vrag",
  "krucefiks",
  "aufbiks",
  "arse",
  "arsehead",
  "arsehole",
  "ass",
  "ass hole",
  "asshole",
  "bastard",
  "bitch",
  "bloody",
  "bollocks",
  "brotherfucker",
  "bugger",
  "bullshit",
  "child-fucker",
  "cock",
  "cocksucker",
  "crap",
  "cunt",
  "dammit",
  "damn it",
  "damned",
  "dick",
  "dick-head",
  "dickhead",
  "dumb ass",
  "dumb-ass",
  "dumbass",
  "dyke",
  "ather-fucker",
  "fatherfucker",
  "frigger",
  "fuck",
  "fucker",
  "fucking",
  "god dammit",
  "God damn",
  "god damn",
  "goddammit",
  "goddamn",
  "Goddamn",
  "goddamned",
  "goddamnit",
  "godsdamn",
  "hell",
  "holy shit",
  "horseshit",
  "in shit",
  "jack-ass",
  "jackarse",
  "jackass",
  "Jesus christ",
  "Jesus fuck",
  "Jesus H. christ",
  "Jesus Harold christ",
  "Jesus, Mary and Joseph",
  "Jesus wept",
  "kike",
  "mother fucker",
  "mother-fucker",
  "motherfucker",
  "nigga",
  "nigra",
  "pigfucker",
  "piss",
  "prick",
  "pussy",
  "shit",
  "shit ass",
  "shite",
  "sibling fucker",
  "sisterfuck",
  "sisterfucker",
  "slut",
  "son of a whore",
  "son of a bitch",
  "spastic",
  "sweet Jesus",
  "twat",
  "wanker",
  "pičku",
  "pićku",
  "pička",
  "pićka",
  "pički",
  "pićki",
  "pičke",
  "pićke",
  "kurac",
  "kurca ",
  "kurci",
  "jebo",
  "jebi",
  "jeba",
  "jebem",
  "anal ",
  "sex",
  "seks ",
];
