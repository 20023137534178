import React, { useEffect, useState } from "react";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import { BsTrash3 as TrashCanIcon } from "react-icons/bs";
import { CiSquareMinus as MinusIcon } from "react-icons/ci";
import { CiSquarePlus as PlusIcon } from "react-icons/ci";

import getDataOneParam from "../Services/getDataOneParam";

import "../Assets/Styles/Pages/ViewCart.css";
import { DIACRITICS, SPECIAL_CHARS } from "../Constants/RegexPatterns";
import { useNavigate } from "react-router-dom";
const ViewCart = () => {
  const navigate = useNavigate();

  const [getOfferDataArr, setGetOfferDataArr] = useState([]);
  const [offerQuantityArr, setOfferQuantityArr] = useState([]);
  const [cartArr, setCartArr] = useState(null);

  const [totalPrice, setTotalPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [priceWithoutTax, setPriceWithoutTax] = useState(0);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // #region ON LOAD
  useEffect(() => {
    const cartItem = JSON.parse(localStorage.getItem("ucart"));
    const getOfferDataArr = [];
    if (cartItem) {
      for (const item of cartItem) {
        getOfferDataArr.push(item.id);
        offerQuantityArr.push(item.quantity);
      }
    }

    setGetOfferDataArr(getOfferDataArr);
  }, []);

  // #region GET DATA
  useEffect(() => {
    if (getOfferDataArr.length > 0) {
      getOfferData();
    }
  }, [getOfferDataArr]);

  // #region get offer data
  const getOfferData = async () => {
    setIsLoading(true);
    try {
      const response = await getDataOneParam(
        process.env.REACT_APP_GET_CART_DATA_URL,
        getOfferDataArr
      );

      setCartArr(response.data.cartData);

      let total = 0;
      for (let i = 0; i < offerQuantityArr.length; i++) {
        total +=
          offerQuantityArr[i] *
          parseInt(response.data.cartData[i].price.replaceAll("€", ""));
      }

      setTotalPrice(total);
      setTax((total * 22) / 100);
      setPriceWithoutTax((total * 78) / 100);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorText(err?.response?.data?.message);
      setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
    }
  };

  // #region HANLDE quantity
  const handleQuantity = (ID, increment) => {
    const newQuantityArr = [...offerQuantityArr];
    if (increment) {
      newQuantityArr[ID] += 1;
    } else {
      newQuantityArr[ID] -= 1;
    }

    setOfferQuantityArr(newQuantityArr);
  };

  // #region REMOVE ITEM
  const removeItem = (ID) => {
    const newArr = cartArr.filter((item) => cartArr.indexOf(item) !== ID);
    setCartArr(newArr);
  };

  useEffect(() => {
    if (cartArr && offerQuantityArr) {
      updateLocalStorage();
    }
  }, [cartArr, offerQuantityArr]);

  // #region UPDATE LOCAL STORAGE
  const updateLocalStorage = () => {
    const storageCart = JSON.parse(localStorage.getItem("ucart"));

    for (let i = 0; i < storageCart.length; i++) {
      storageCart[i].quantity = offerQuantityArr[i];
    }

    const newArr = [];

    for (let i = 0; i < cartArr.length; i++) {
      newArr.push({ id: cartArr[i].ID, quantity: offerQuantityArr[i] });
    }

    let total = 0;
    for (let i = 0; i < cartArr.length; i++) {
      total +=
        offerQuantityArr[i] * parseInt(cartArr[i].price.replaceAll("€", ""));
    }

    setTotalPrice(total);
    setTax((total * 22) / 100);
    setPriceWithoutTax((total * 78) / 100);
    localStorage.setItem("ucart", JSON.stringify(newArr));
  };

  // #region HANDLE DEFAULT IMAGE
  const handleDefaultImage = (e) => {
    e.target.src = "/addImageIcon.svg";
  };

  // #region EMPTY CART
  const emptyCart = () => {
    localStorage.removeItem("ucart");
    window.location.reload(false);
  };
  return (
    <div className="view-cart-page">
      <Header />
      <div className="view-cart-box">
        <div className="view-cart-main">
          <div className="view-cart-title">Odkrij voziček</div>
          <div className="view-cart-table">
            <div className="table-column-title">
              <span>Produkt</span>
              <span>Ime</span>
              <span>Količina</span>
              <span>Cena</span>
              <span>Skupaj</span>
              <span>Odstrani</span>
            </div>
            {cartArr &&
              cartArr.map((item, key) => (
                <div key={key} className="view-cart-item">
                  <span className="cart-cell">
                    <img
                      src={`${process.env.REACT_APP_OFFER_IMAGES_URL}${
                        item.companyId
                      }-${item.name
                        .toLowerCase()
                        .replaceAll(DIACRITICS, "")
                        .replaceAll(SPECIAL_CHARS, "")}-${item.offerType}.png`}
                      alt="Slika ponudbe podjetja!"
                      onError={(e) => handleDefaultImage(e)}
                      className="cart-item-img"
                    />
                  </span>
                  <span className="cart-cell">{item.name}</span>
                  <span className="cart-cell">
                    <span
                      className="quantity-button"
                      onClick={() => handleQuantity(key, false)}
                    >
                      -
                    </span>
                    <span className="quantity-value">
                      {offerQuantityArr[key]}
                    </span>
                    <span
                      className="quantity-button"
                      onClick={() => handleQuantity(key, true)}
                    >
                      +
                    </span>
                  </span>
                  <span className="cart-cell">
                    {item.price.replaceAll("€", "")}€
                  </span>
                  <span className="cart-cell">
                    {offerQuantityArr[key] *
                      parseInt(item.price.replaceAll("€", ""))}
                    €
                  </span>
                  <span className="cart-cell">
                    <TrashCanIcon
                      onClick={() => removeItem(key)}
                      className="view-cart-remove-item-icon"
                    />
                  </span>
                </div>
              ))}
          </div>
          <div className="view-cart-button-box">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="button-3"
            >
              Nadaljuj z nakupovanjem
            </button>
            <button onClick={emptyCart} className="button-2">
              Izprazni voziček
            </button>
          </div>
        </div>

          <div className="view-cart-finnish">
            <div className="order-details-box">
              <div className="order-details-title">Povzetek naročila</div>
              <div className="order-details-price">
                <span>Cena brez DDV:</span>
                <span>{priceWithoutTax.toFixed(2)}€</span>
              </div>
              <div className="order-details-price">
                <span>DDV:</span>
                <span>{tax.toFixed(2)}€</span>
              </div>
              <div className="order-details-price" id="total-price">
                <span>Skupaj:</span>
                <span>{totalPrice.toFixed(2)}€</span>
              </div>
              <div className="order-details-pay-button-box">
                <button className="button-1">PLAČAJ</button>
              </div>
            </div>
          </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewCart;
