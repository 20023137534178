import axios from "axios";

const getCompanyAdministrationData = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_GET_COMPANY_ADMINISTRATION_DATA, {
        withCredentials: true,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getCompanyAdministrationData;
