import React, { useState } from "react";
import { NUMBER_REGEX } from "../Constants/RegexPatterns";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import verifyEmail from "../Services/verifyEmail";
import ErrorWindow from "./ErrorWindow";
import WarningWindow from "./WarningWindow";
import requestNewVerificationCode from "../Services/requestNewVerificationCode";
import { useNavigate } from "react-router-dom";
import deleteUser from "../Services/deleteUser";

import "../Assets/Styles/Components/VerifyEmail.css";
import InputBox from "./InputBox";

const VerifyEmail = (props) => {
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState(undefined);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const [numberOfTries, setNumberOfTries] = useState(3);

  const [warning, setWarning] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [warningTitle, setWarningTitle] = useState("");

  const inputChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const submitVerificationCode = async () => {
    if (verificationCode) {
      if (!NUMBER_REGEX.test(verificationCode)) {
        setError(true);
        setErrorTitle("NAPAČEN VNOS");
        setErrorText(
          "Vaš vnos je napačne oblike mora biti številka. (00000000)"
        );
      }

      try {
        if (props?.password) {
          const response = await verifyEmail(
            props.email,
            verificationCode,
            props.password
          );

          if (response.status === 200) {
            if (numberOfTries === 1) {
              setWarning(true);
              setWarningTitle("PRESEŽENO ŠTEVILO POSKUSOV");
              setWarningText(
                "Presegli ste dovoljeno število poskusov, lahko spremeneite e-naslov (če potrjujete podjetje), ali zaporosite za novo kodo."
              );
              setNumberOfTries(numberOfTries - 1);
              return;
            }

            if (!response.data.verified) {
              setNumberOfTries(numberOfTries - 1);
              return;
            }

            if (
              response.data.verified === true ||
              response.data.verified === "true"
            ) {
              console.log(response)
              props.handleverifyemailwindow();
              window.location.reload();
            }
          }
        } else {
          const response = await verifyEmail(
            props.email,
            verificationCode,
            undefined
          );
          if (response.status === 200) {
            if (numberOfTries === 1) {
              setWarning(true);
              setWarningTitle("PRESEŽENO ŠTEVILO POSKUSOV");
              setWarningText(
                "Presegli ste dovoljeno število poskusov, lahko spremeneite e-naslov (če potrjujete podjetje), ali zaporosite za novo kodo."
              );
              setNumberOfTries(numberOfTries - 1);
              return;
            }

            if (!response.data.verified) {
              setNumberOfTries(numberOfTries - 1);
              return;
            }

            navigate("/");
          }
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setErrorTitle(error?.response?.data?.message || error?.message);
        setErrorTitle(
          ErrorCodesParser(error?.response?.data?.errorCode) ||
            ErrorCodesParser(603)
        );
      }
    }
  };

  const requestNewCode = async () => {
    try {
      const response = await requestNewVerificationCode(props.email);

      if (response.status === 200) {
        setNumberOfTries(3);
        setWarning(false);
      }
    } catch (error) {
      setError(true);
      setErrorTitle(error?.response?.data?.message || error?.message);
      setErrorTitle(
        ErrorCodesParser(error?.response?.data?.errorCode) ||
          ErrorCodesParser(603)
      );
    }
  };

  const changeEmail = async () => {
    try {
      const response = await deleteUser(props.email);

      console.log(response);

      if (response.status === 200) {
        setWarning(!warning);
        props.handleverifyemailwindow();
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorTitle(error?.response?.data?.message || error?.message);
      setErrorTitle(
        ErrorCodesParser(error?.response?.data?.errorCode) ||
          ErrorCodesParser(603)
      );
    }
  };

  return (
    <div className="Verification-page">
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={() => setError(!error)}
          btntext={"RAZUMEM"}
        />
      )}

      {warning && (
        <WarningWindow
          title={warningTitle}
          text={warningText}
          gobackbtn={props.changeemail ? "Spremeni e-naslov" : "Nazaj"}
          proceedbtn="Nova koda"
          goback={
            props.changeemail
              ? changeEmail
              : () => {
                  setWarning(!warning);
                  props.handleverifyemailwindow();
                }
          }
          proceed={requestNewCode}
        />
      )}
      <div className="Verification-box">
        <h2>Potrditev E-naslova</h2>
        <div className="Verification-body">
          <div className="Verification-message">
            Prosimo vnesite 8 mestno kodo, ki smo jo poslali na vaš e-naslov:{" "}
            {props.email}.
          </div>
          <div className="Verification-input">
            <InputBox
              label="Potrditvena koda:"
              id="input-bar-1"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container-center"
              name="varificationCode"
              placeholder="Vnesi potrditveno kodo"
              type="text"
              onchange={inputChange}
            />
            <span className="try-number">{numberOfTries}</span>
          </div>
          <div>
            <button
              className="button-1"
              type="button"
              onClick={submitVerificationCode}
            >
              Potrdi
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
