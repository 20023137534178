import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import "../Assets/Styles/Pages/AboutUs.css";
const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="aboutus-page">
        <div className="aboutus-box">
          <span className="aboutus-title">OPIS</span>
          <span className="aboutus-text">
            Odkrij.si je inovativna spletna platforma, namenjena vsem slovenskim
            podjetjem, ki želijo izboljšati svojo spletno prisotnost, enostavno
            dostopati do širšega kroga strank in olajšati poslovno komunikacijo.
            Platforma združuje različne funkcionalnosti, ki podjetjem pomagajo
            pri digitalizaciji poslovanja, od registracije osnovnih podatkov do
            vzpostavitve spletne trgovine. Z uporabo Odkrij.si lahko podjetja
            preprosto predstavijo svoje storitve in izdelke ter vzpostavijo
            učinkovitejšo povezavo s strankami.
            <br></br>
            <br></br>
            <ul>
              <li>
                <b>Register slovenskih podjetij:</b> Vsebuje osnovne podatke o
                podjetjih, ki so koristni tako za poslovne kot za fizične
                partnerje. Podatke vnesejo podjetja sama, kar zagotavlja
                verodostojnost in kredibilnost informacij. Naknadno podatke
                preverimo tudi mi, s čimer dodatno zagotavljamo njihovo točnost.
              </li>
              <li>
                <b>Spletna vizitka:</b> Želimo podjetjem poenostaviti pot do
                spletne prisotnosti, zmanjšati stroške in prihraniti čas pri
                pridobivanju spletne vizitke ter digitalizaciji poslovanja. Na
                Odkrij.si lahko podjetje vnese svoje podatke, na podlagi katerih
                se samodejno ustvari spletna vizitka. Enotna predloga za vsa
                podjetja poenostavi iskanje informacij, saj stranke ne
                potrebujejo brskati po zapletenih straneh ali podstraneh, kar
                izboljša uporabniško izkušnjo.
              </li>
              <li>
                <b>Spletna trgovina:</b> Namenjena je omogočanju enakopravne
                možnosti spletne prodaje za vsa slovenska podjetja. V današnjem
                času, ko se večina izdelkov prodaja prek spleta, še vedno
                obstaja veliko podjetij, ki nimajo ustreznih pogojev, sredstev
                ali znanja za vzpostavitev spletne trgovine. Odkrij.si
                predstavlja preprosto in dostopno rešitev, ki je na voljo po
                nizki ceni in pod enakimi pogoji za vse. Poleg tega omogoča tudi
                prodajo ali naročanje storitev, saj lahko stranke pošljejo
                povpraševanje za določeno storitev. Odkrij.si tako deluje kot
                povezava med strankami in izvajalci ter spodbuja učinkovito
                komunikacijo med njimi.
              </li>
            </ul>
          </span>
        </div>
        <div className="aboutus-box">
          <span className="aboutus-title">CILJI</span>
          <span className="aboutus-text">
            <ul>
              <li>
                <b>Zbiranje vseh slovenskih podjetij na enem mestu:</b>
                Prizadevamo si, da Odkrij.si postane platforma, na kateri bodo
                uporabniki enostavno dostopali do podatkov o podjetjih, si
                ogledali njihovo ponudbo ter imeli možnost naročila izdelkov ali
                storitev. Ta pristop bo uporabnikom omogočil hitro iskanje
                informacij, ki jih potrebujejo, in vzpostavitev neposrednega
                stika s podjetji, kar bo povečalo preglednost in dostopnost.
              </li>
              <li>
                <b>Poenostavitev digitalizacije slovenskih organizacij:</b> Naš
                cilj je omogočiti podjetjem, da v celoti izkoristijo svoj
                potencial. S tem jim želimo pomagati razširiti bazo strank in
                povečati zaslužek. Z dostopom do ustreznih digitalnih orodij in
                rešitev bodo podjetja lahko izboljšala svojo spletno prisotnost
                in uspešnost na trgu.
              </li>
              <li>
                <b>Povečanje vidnosti in komunikacije:</b> Želimo spodbuditi
                aktivno komunikacijo med podjetji in uporabniki. Z izboljšanjem
                dostopnosti informacij in poenostavitvijo interakcije med obema
                stranema želimo ustvariti sinergijo, ki bo prinesla koristi tako
                podjetjem kot tudi strankam.
              </li>
              <li>
                <b>Spodbujanje rasti slovenskega gospodarstva:</b> S povečanjem
                spletne prisotnosti podjetij in z digitalizacijo želimo
                prispevati k rasti slovenskega gospodarstva.
              </li>
            </ul>
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
