import axios from "axios";

const getUsersData = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_GET_USER_DATA, {
        withCredentials: true,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export default getUsersData;
