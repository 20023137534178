import React from 'react'

import "../Assets/Styles/Components/LoaderSmall.css"
const LoaderSmall = () => {
  return (
    <span className='loader-small'></span>
  )
}

export default LoaderSmall
